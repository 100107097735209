import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (!(e instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewChecked() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     (window as any).ga('set', 'page', event.urlAfterRedirects);
    //     (window as any).ga('send', 'pageview');
    //   }
    // });
  }

}
