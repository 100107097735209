import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sharers',
  templateUrl: './sharers.component.html',
  styleUrls: ['./sharers.component.scss']
})
export class SharersComponent implements OnInit {
   @Input() fb;
   @Input() tw;
   @Input() wh;

  constructor() { }

  ngOnInit(): void {
  }

}
