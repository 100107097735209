import {Component, OnInit} from '@angular/core';
import {HeadersService} from '../../services/headers.service';

@Component({
  selector: 'app-elia',
  templateUrl: './elia.component.html',
  styleUrls: ['./elia.component.scss']
})
export class EliaComponent implements OnInit {

  constructor(
    private header: HeadersService,
  ) {
  }

  ngOnInit(): void {
    this.header.setHeader(
      'Desde que estoy en el colectivo siento que hago algo por encontrarlo; ' +
      'el gobierno no hace nada con el tema de los desaparecidos,ni escucha, ni ayuda ni da protección.' +
      ' Como si estuvieras hablando a la pared.',
      '/assets/portadas/elia-d.jpg,',
      'Elia: Ayudar a otros mientras se busca un hijo | POPLab',
      '/elia'
    );
  }

}
