<div class="intro-container black d-flex justify-content-center">
  <div class="col align-self-center">
    <img src="assets/portadas/vero.jpg" alt="" class="img-fluid d-none d-md-block">
    <img src="assets/portadas/vero-m.jpg" alt="" class="img-fluid d-block d-md-none">
  </div>
</div>
<div class="container">
  <app-sharers
    fb="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuscadoras.poplab.mx"
    tw="https://twitter.com/intent/tweet?text=Cuando%20ellas%20buscan%20&url=https%3A%2F%2Fbuscadoras.poplab.mx&via=poplabmx"
    wh="https://wa.me/?text=Cuando%20ellas%20buscan%20https%3A%2F%2Fbuscadoras.poplab.mx&via=poplabmx%20"
  ></app-sharers>

  <img src="assets/nav/cuandoEllasBuscan-1.jpg" class="img-fluid d-lg-none" alt="">
  <p>Por:<b> Verónica Espinosa.</b></p>
  <p> “Fui niña feliz, joven feliz, casada feliz, hasta que me llegó el zarpazo de la represión cuando me quitaron un
    hijo. Y empecé a ser la madre de un desaparecido” contó doña Rosario Ibarra de Piedra a la cineasta Shula Erenberg
    en el documental que lleva su nombre, “Rosario”.</p>
  <p>Madres de Plaza de Mayo en Argentina; Rosario Ibarra de Piedra y el Comité Eureka en México: las referentes
    latinoamericanas de las mujeres buscadoras de personas desaparecidas encabezan esa lista interminable que comenzó en
    la segunda mitad de los años setenta y que sigue sumando nombres cada día en Latinoamérica. </p>
  <p>En México. En Guanajuato.</p>
  <p> Son momentos distintos de una misma historia. La dictadura militar en Argentina y el periodo denominado “Guerra
    sucia” en México, fueron etapas marcadas por el abuso de las fuerzas militares y de corporaciones policiacas como la
    desaparecida Dirección Federal de Seguridad (DFS), cuyos integrantes sustrajeron a Jesús Piedra Ibarra, hijo de
    Rosario, en abril de 1975.</p>
  <p>En ambos casos, fueron mandos oficiales los que dieron la orden para que decenas o miles de personas fueran
    privadas
    de su libertad, con destino incierto y muy pocos casos de localización.</p>
  <p> En el 2006, otra guerra se desataba en México, oficializada como una estrategia de combate a los grupos del
    narcotráfico y la delincuencia organizada del gobierno del presidente panista Felipe Calderón.</p>
  <p> Entonces, el infierno llegó a las puertas de miles de familias en Chihuahua, Nuevo León, Tamaulipas, Veracruz,
    Guerrero, Coahuila. Personas de todas edades, sexos, condición socioeconómica, historia personal, comenzaron a ser
    desaparecidas. </p>
  <p>Y fueron mujeres quienes en su mayoría salieron a buscarlas. </p>
  <p>No es una actividad únicamente a cargo de las mujeres, ciertamente. Pero los números, los liderazgos y la presencia
    de ellas son abrumadoramente mayoritarios a cargo de la infausta tarea.</p>
  <p>¿Razones, una explicación? Muchas. Hay un desempeño social, el de las mujeres como cuidadoras de cada integrante de
    su familia, desde los primeros años hasta los últimos minutos. </p>
  <p>Hay una culpa inculcada. “Te culpas de haber nacido y de haberlos traído al mundo”, dijo alguna vez la potosina
    Edith
    Pérez.</p>
  <p>Hay un afán de preservar la memoria, de desterrar el olvido. Lo tienen las buscadoras, como lo tienen las mujeres
    que
    van a las prisiones a visitar a sus familiares internos, porque de lo que se trata es de no abandonar ni con el
    pensamiento al otro.</p>
  <p>Y entonces se conocieron otros nombres, como el de Silvia Ortiz, fundadora del colectivo “Vida” en 2004 en
    Coahuila;
    o el de Mirna Nereyda Medina, al frente de “Las Buscadoras del Fuerte” en Sinaloa en el 2014; María Herrera, quien
    tras la desaparición de cuatro de sus hijos en Michoacán creó “Enlaces nacionales” en el 2011. O Edith Pérez, quien
    en
    San Luis Potosí creó el colectivo “Voz y dignidad por los nuestros” después de que, en el 2012, su hermano, sus dos
    hijos y su sobrina desaparecieron por Ciudad Mante.</p>
  <p> Mientras tanto, la criminalidad envenenaba silenciosa pero inevitablemente al estado de Guanajuato. Ningún
    antídoto
    surgió de autoridades, éstas más bien ufanas en aparentar, en mentir y en colocar a cualquier víctima de
    desaparición
    en el camino de los malos pasos.</p>
  <p> Como pasó en el resto del país, en Guanajuato las buscadoras caminaron primero solas. Fueron ignoradas, fueron
    vejadas a la manera en que las instituciones y sus funcionarios lo hacen, cerrando puertas en las instancias
    obligadas
    -ellas sí- a buscar y encontrar a sus familiares desaparecidos. </p>
  <p>Fueron y son todavía amenazadas e intimidadas.</p>
  <p> Pero el hartazgo también une y hoy levantan la frente; se sientan frente a autoridades, reclaman, piden cuentas,
    mientras hacen lo que aprenden y aprendieron a hacer cuando les cambió la vida: escarbar la tierra hoy inhóspita de
    Guanajuato para rastrear a los suyos.</p>
  <p> En POPLab ponemos la mirada en estas buscadoras guanajuatenses, en un intento por aportar a la construcción de esa
    dolorosa pero necesaria memoria colectiva de la desaparición de personas en Guanajuato. </p>
  <p>En su propia voz, las buscadoras Bibiana, Elia, Matilde y Mariana cuentan sus historias para tampoco ser olvidadas.
    Para que, como Rosario Ibarra, como Mirna Medina, como las Madres de Plaza de Mayo, sepamos sus nombres, sus
    historias, sus pérdidas, frente a un perverso afán institucional de borrarlas de la historia oficial, en ese mismo
    afán de borrar también uno de los más prolongados y oscuros episodios de impunidad en México y en Guanajuato.</p>
  <p>Y entonces descubrimos que estas mujeres se construyeron como las buscadoras que son, porque han logrado sobrevivir
    y
    han dejado atrás todo tipo de violencias y entornos agraviantes: embarazos adolescentes; violencia familiar de
    padres
    o esposos, adicciones, pobreza, discriminación. </p>
  <p>Es su propio rastro el que reconoceremos en estas historias.</p>
  <p>Hay una violencia estructural que persiste, porque ahora autoridades de cualquier nivel no les reciben las
    denuncias
    por la desaparición de sus familiares; no les permiten conocer las investigaciones; no las acompañan a rastrear; no
    les contestan el teléfono. La violencia institucional, por si fuera poco.</p>
  <p> Esta es una de las muchas razones por las que tres de estas buscadoras son presentadas en esta serie con nombres
    ficticios. Porque, lamentablemente, seguirán saliendo a buscar y en estas tierras guanajuatenses les aguardan
    riesgos
    que no se ven conjurados en el corto plazo por quienes son responsables de hacerlo.</p>
  <p> Cuando ellas buscan, lo hacen llevando a cuestas todo lo que implica su decisión: hacen a un lado sus propias
    vidas;
    muchas veces dejan la vida en común con sus familias, la que -como nos dicen todas en esta serie- de por sí ya no
    será
    la misma. Pero nunca dejan de mirar esa fotografía, la de aquéllos que les fueron arrebatados bajo cualquier
    circunstancia.</p>
  <p> Porque, parafraseando a Rosario Ibarra de Piedra: “A nuestros (hijos, hijas, hermanos, padres) no les vamos a
    quitar
    la vida ni con el pensamiento”.</p>
  <div class="spacer"></div>
  <div class="row">
    <a routerLink="/bibiana" class="col-md-6">
      <div class="view overlay zoom">
        <img src="assets/portadas/bibiana-d.jpg" class="" style="height: auto;width: 100%;" alt="">
        <div class="mask flex-center waves-effect waves-light">
        </div>
      </div>
    </a>
    <a routerLink="/elia" class="col-md-6">
      <div class="view overlay zoom">
        <img src="assets/portadas/elia-d.jpg" class="img-fluid" alt="">
        <div class="mask flex-center waves-effect waves-light ">
        </div>
      </div>
    </a>
    <a routerLink="/mariana" class="col-md-6">
      <div class="view overlay zoom">
        <img src="assets/portadas/mariana-d.jpg" class="img-fluid" alt="">
        <div class="mask flex-center waves-effect  waves-light ">
        </div>
      </div>
    </a>
    <a routerLink="/matilde" class="col-md-6">
      <div class="view overlay zoom">
        <img src="assets/portadas/matilde-d.jpg" class="img-fluid" alt="">
        <div class="mask flex-center waves-effect waves-light ">
        </div>
      </div>
    </a>
  </div>

</div>
