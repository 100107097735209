import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

import {MDBBootstrapModulesPro, MDBSpinningPreloader, ToastModule} from 'ng-uikit-pro-standard';
import {BibianaComponent} from './pages/bibiana/bibiana.component';
import {EliaComponent} from './pages/elia/elia.component';
import {MarianaComponent} from './pages/mariana/mariana.component';
import {MatildeComponent} from './pages/matilde/matilde.component';
import {HomeComponent} from './pages/home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {CreditsComponent} from './shared/credits/credits.component';
import {SharersComponent} from './shared/sharers/sharers.component';
import {GtagModule} from 'angular-gtag';


@NgModule({
  declarations: [
    AppComponent,
    BibianaComponent,
    EliaComponent,
    MarianaComponent,
    MatildeComponent,
    HomeComponent,
    NavbarComponent,
    CreditsComponent,
    SharersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    GtagModule.forRoot({trackingId: 'G-JX3W2K0TWY', trackPageviews: true}),
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
