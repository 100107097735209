import {Component, OnInit} from '@angular/core';
import {HeadersService} from '../../services/headers.service';

@Component({
  selector: 'app-mariana',
  templateUrl: './mariana.component.html',
  styleUrls: ['./mariana.component.scss']
})
export class MarianaComponent implements OnInit {

  constructor(
    private header: HeadersService,
  ) {
  }

  ngOnInit(): void {
    this.header.setHeader(
      'Yo no tengo porqué esconderme, he hecho las cosas bien. Si hay alguien que no ha actuado bien, son las autoridades',
      '/assets/portadas/mariana-d.jpg',
      'Lo busqué sin parar, lo encontré, pero no se ha hecho justicia: Mariana | POPLab',
      '/mariana'
    );
  }

}
