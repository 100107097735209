import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

interface HeaderObjectModel {
  img: string;
  description: string;
  title: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeadersService {
  g = 'https://buscadoras.poplab.mx';
  private header: HeaderObjectModel;

  constructor(
    private meta: Meta,
    private title: Title,
  ) {
  }

  cleanHeader() {
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:url"');
  }

  setHeader(description, img, title, url) {
    this.cleanHeader();
    this.header = {
      description,
      img: this.g + img,
      title,
      url: this.g + url
    };
    this.title.setTitle(this.header.title);
    this.meta.addTag({property: 'og:description', content: this.header.description});
    this.meta.addTag({name: 'description', content: this.header.description});
    this.meta.addTag({property: 'og:image', content: this.header.img});
    this.meta.addTag({property: 'og:title', content: this.header.title});
    this.meta.addTag({property: 'og:url', content: this.header.url});
    this.meta.addTag({property: 'twitter:card', content: 'sumary' });
    this.meta.addTag({property: 'twitter:site', content: '@poplabmx' });
  }
}
