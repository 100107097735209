import { Component, OnInit } from '@angular/core';
import {HeadersService} from '../../services/headers.service';

@Component({
  selector: 'app-bibiana',
  templateUrl: './bibiana.component.html',
  styleUrls: ['./bibiana.component.scss']
})
export class BibianaComponent implements OnInit {

  constructor(
    private header: HeadersService,
  ) { }

  ngOnInit(): void {
    this.header.setHeader(
      'Cuando nos restregó la fiscal que Manuel no andaba en cosas buenas, le dije: “si le consta que mi hermano es un delincuente,' +
      'búsquelo y métalo a la cárcel, para eso hay leyes y no para que anden desapareciendo gente”',
      '/assets/portadas/bibiana-d.jpg',
      'Bibiana: Aunque muramos de miedo si no buscamos nadie lo hara | POPLab',
      '/bibiana'
    );
  }

}
