<div class="intro-container black d-flex justify-content-center">
  <div class="col align-self-center">
    <img src="assets/bibiana/0.jpg" alt="" class="img-fluid d-none d-md-block">
    <img src="assets/portadas/bibana-m.jpg" alt="" class="img-fluid d-block d-md-none">
  </div>
</div>
<div class="container">
  <app-sharers
    fb="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuscadoras.poplab.mx%2Fbibiana"
    tw="https://twitter.com/intent/tweet?text=Cuando%20ellas%20buscan%3A%20bibiana&url=https%3A%2F%2Fbuscadoras.poplab.mx%2Fbibiana&via=poplabmx"
    wh="wa.me/?text=Cuando%20ellas%20buscan%3A%20bibiana%20https%3A%2F%2Fbuscadoras.poplab.mx%2Fbibiana&via=poplabmx%20"
  ></app-sharers>

  <h5>
    <i>
      Cuando nos restregó la fiscal que Manuel no andaba en cosas buenas, le dije: “si le consta que mi hermano es un
      delincuente, búsquelo y métalo a la cárcel, para eso hay leyes y no para que anden desapareciendo gente”
    </i>
  </h5>

  <p>Por:<strong> Kennia Velázquez</strong></p>

  <img src="assets/bibiana/1.jpeg" class="img-fluid my-5" alt="">

  <p>Cuando se llevaron a mi hermano Manuel, se llevaron la parte más bonita de mi vida. Aunque siempre nos
    sentimos
    abandonados por el lado paterno, yo sentía que a pesar de la pobreza y las dificultades lo tenía todo porque
    sabía que
    mis hermanos estaban bien y había mucho amor.</p>

  <p>Nuestra vida siempre ha sido un poquito complicada. Después de que enviudara, mi mamá se dedicó a trabajar,
    sola nos
    sacó adelante, nos dejaba encargados con gente en el rancho Refugio de Munguía, para poderse ir a trabajar
    como
    empleada doméstica a Irapuato. Le pagaba a gente para que nos cuidara, nos mandaran a la escuela o nos dieran
    de comer
    en lo que ella llegaba del trabajo.</p>

  <p>Somos cuatro hermanos, yo soy la tercera y Manuel es el segundo, él y mi hermana mayor se fueron a vivir con
    mi
    abuela materna y yo los extrañaba mucho. Cada ocho días los íbamos a visitar y para mí era una alegría enorme
    verlos,
    comer juntos era maravilloso, como una celebración. A él le gustaba todo lo que preparaba mi mamá, no hacían
    falta
    grandes cosas, nos íbamos al campo y recogíamos verdolagas y mi abuelita solía hacerlas con chile súper
    picoso,
    frijoles y tortillas hechas a mano y con eso éramos felices. Luego nos íbamos a pasear, a ver el cerro, a
    correr por
    el campo.</p>

  <p>Ninguno de los cuatro acabamos la escuela: Manuel fue un tiempo a la secundaria y luego se metió a trabajar
    en el
    campo; yo sin avisarle a mi mamá dejé la escuela primaria, porque yo estaba muy chica y se veía mucha droga,
    me
    faltaba medio año para terminar sexto, ya hasta ahora de adulta, trabajando y con mis niños terminé hasta la
    preparatoria.</p>

  <p>A los 13 años comencé haciendo limpieza en casas, luego tuve suerte, porque me llegaron a contratar como
    secretaria
    sin tener la escuela terminada y más recientemente, estuve en la empresa automotriz General Motors en Silao,
    como
    operaria.</p>

  <p>Siempre nos frecuentábamos, nos procurábamos. Ahora que desapareció Manuel las cosas han cambiado mucho, la
    familia
    se ha distanciado. La desaparición de Manuel nos cambió la vida.</p>
  <img src="assets/bibiana/2.jpeg" class="img-fluid my-5" alt="">

  <p>Cuando se dio la desaparición de Manuel, yo todavía estaba trabajando y estaba embarazada. La última vez que
    estuvimos juntos, tenía cinco meses de embarazo y en esos días no lo veía mucho, estudiaba y trabajaba, los
    turnos en
    General Motors son de doce horas. Había días que salía de trabajar y sin haber dormido toda la noche, me iba a
    la
    preparatoria, y luego a atender a mi niño. Y el resto del día a lavar, recoger, planchar y demás cosas de la
    casa.
    Casi no me daba tiempo de ir a ver a Manuel. A mí me quedó remordimiento de conciencia de no haberlo
    frecuentado todo
    ese tiempo.</p>

  <p>Manuel desapareció el 8 de enero de 2018. Dejé de trabajar por la desaparición y porque estaba a punto de dar
    a luz,
    me puse muy enferma, pensé que iba a perder a mi hijo. De hecho mi mamá me dijo que Manuel les había marcado
    para que
    yo no me preocupara. En febrero nació mi segundo hijo y decidí salirme para cuidarlo y porque me sentía bien
    desesperada y quería salir a buscar a mi hermano.</p>

  <p>Yo no sabía que había desaparecidos, veía en Facebook que estaban matando mucha gente en Irapuato, me daba
    miedo,
    pero me sentía tan alejada de esa situación de violencia. A mí el que me preocupaba era mi hermano el chico
    que se iba
    de fiesta con sus amigos y cada vez que sabía de algún suceso violento lo buscaba.</p>

  <p>Manuel era tan buena persona y yo sentía que estaba a salvo en el rancho… nunca me imaginé que íbamos a pasar
    por un
    tema de desaparición en mi familia. Él siempre trabajó en el campo, en granjas lecheras, en unos terrenos de
    maíz, en
    campos de fresas… a mí no me queda claro por qué se lo llevaron, yo he preguntado si andaba en malos pasos y
    la gente
    dice que no. Que lo veían trabajando en su moto repartiendo molino para los puercos en las tiendas. Yo fui al
    rancho a
    preguntarle a la gente qué sabía, pero nadie me quiso decir nada. Sólo mi tío -que tiene 80 años- me dijo que
    unos
    hombres armados se lo llevaron.</p>

  <p>Ese día me habló mi cuñada como a las 10 de la noche y me dijo que mi hermano no había llegado del trabajo.
    Cuando
    colgué el teléfono salí a poner la denuncia y luego le avisé que había denunciado y ella me pidió que la
    quitara,
    porque tenía mucho miedo, no quiere saber nada de la búsqueda y abandonó la casa donde vivían.</p>

  <p>Ella sabe que yo lo estoy buscando, entonces sólo me comunico cuando les consigo apoyos para ella y sus dos
    niños.
    Pero casi no me comunico con ellos. Yo no tengo manera de ayudarlos, no tengo solvencia económica. Dependo de
    lo que
    mi pareja me da, pero he tratado de que las autoridades se hagan un poquito responsables de eso y trato de
    conseguirles las más ayudas que puedo.</p>

<p class="text-center">✽✽✽</p>
  <p>Aquí lo malo es que la Fiscalía nunca investigó absolutamente nada, lo único que había en su carpeta era mi
    declaración y nada más. Yo iba diario al SEMEFO a buscarlo y siempre me decían que no había cuerpos sin
    reconocer.</p>

  <p>Una vez mi mamá me acompañó a revisar los avances de la investigación y pasó una persona que al ver a mi mamá
    llorando le dijo: “ay señora no esté aquí con su <em>lagrimerío</em>, si su hijo anduviera en cosas buenas,
    usted no
    estaría aquí. Vienen aquí a chillar pero primero no cuidan a sus hijos.” Después supe que esa mujer era la
    fiscal en
    Irapuato.</p>

  <p>Yo me exalté mucho y le dije “usted ni conoce a mi hermano, no han investigado nada. Si a usted le consta que
    mi
    hermano es un delincuente, búsquelo y métalo a la cárcel, pero no le falte el respeto a mi mamá. Para eso hay
    leyes,
    para que los delincuentes estén en la cárcel y no para que anden desapareciendo gente”.</p>

  <p>Duele mucho la discriminación de la gente conmigo y con mis compañeras. Yo creo que aún la gente que anda en
    malos
    pasos no se merece morir de esas maneras, no se merecen estar desaparecidos. Cuando te desaparecen a alguien,
    desaparecen tu manera de sentir, de vivir, la manera de convivir con las personas.</p>

  <p>Después conocí al colectivo “A tu encuentro”. En una publicación en Facebook que decía: “todas las personas
    que
    tengan un familiar desaparecido y quieran información los vamos a esperar en el DIF de Irapuato”. Yo tenía
    mucho miedo
    de ir, no sabía quién convocaba la marcha, no sabía si era verdad. Mi pareja estaba preocupada de que fuera,
    me decía
    “¿a qué vas? ¿Y si te pasa algo?” pero de todas formas fui.</p>

  <p>Ahí nos atendió la fiscal y nos trató horrible, nos dijo que nosotras no éramos víctimas de nada. El
    colectivo fue
    creciendo y ahora somos muchas las familias, cada día hay más gente desapareciendo.</p>

  <p>Una creería que porque nos hemos reunido con el gobernador Diego Sinhue, con el fiscal Carlos Zamarripa, con
    el
    alcalde de Irapuato, Ricardo Ortiz, con el encargado de seguridad, que las cosas iban ser mejor, pero sólo
    aprendieron
    a fingir que nos tratan bien. Yo hoy voy a la fiscalía y ya no me tratan mal, pero nos dan atole con el dedo.
    Nos
    dicen que les pidamos lo que necesitemos, que les marquemos a cualquier hora, “estamos trabajando, usted no se
    preocupe”. Pero ves la carpeta y todo sigue igual.</p>

  <p>Yo era muy ingenua, la primera vez que nos reunimos con el gobernador y que nos abrazó y nos dijo que estaba
    con
    nosotras, que iba a salir la ley de desaparecidos por decreto y la comisión de búsqueda, yo salí feliz. Yo le
    creí,
    pensé que iba a encontrar a mi hermano. Pero van pasando los días, los meses y me doy cuenta que fueron
    palabras,
    fotos para la prensa y ahí quedó todo. Ya no le creo a ninguna de las autoridades.</p>
  <img src="assets/bibiana/3.jpeg" class="img-fluid my-5" alt="">

  <p>Un año viví con mis abuelos porque mi mamá planeaba irse a Estados Unidos. Hice el cuarto de primaria en el
    rancho,
    fue la época que más conviví con Manuel y con mi hermana.</p>
  <p>Manuel iba en la secundaria y mi escuela estaba a un lado, de regreso nos íbamos caminando, era un camino muy
    largo y
    corríamos y nos jalábamos los cachetes, nos hacíamos cosquillas y bromas pesadas.</p>
  <p>Cuando se llevaron Manuel se llevaron a la persona que más me ha hecho reír en mi vida, en esa época él ya
    trabajaba
    y lo esperábamos para comer, porque aunque llegara cansado y de mal humor, no faltaba que hiciera un chiste
    que nos
    alegrara, por eso lo esperábamos con muchas ansias.</p>
  <p>En el tiempo en el que viví en el rancho Manuel me llevaba a las fiestas. él nos daba dinero, no ganaba
    mucho, pero
    le alcanzaba para subirnos a los juegos a mí y a mis hermanos, éramos felices… Todos esos recuerdos los guardo
    con
    mucho cariño, yo sé que tal vez no es mucho que alguien te dé para un juego, pero sí lo es cuando no hay
    dinero y hay
    necesidades en la casa, entonces eso lo valoras muchísimo.</p>
  <p>Luego de ese año yo regresé con mi mamá y Manuel y mi hermana se quedaron con mis abuelos, pero aunque no
    vivimos
    juntos nos queríamos mucho.</p>
  <p>Él era muy cariñoso conmigo, teníamos los dos un apego emocional muy grande, siempre fui su apoyo y él fue el
    mío. Ya
    grandes, una pareja que tuve me engañó, Manuel estuvo conmigo, siempre me decía “tú vales mucho, vas a salir
    adelante,
    no lo busques”. Y yo le decía: tú me dices eso porque me quieres y soy tu hermana y él me dijo: “precisamente,
    porque
    nadie te conoce mejor que yo, sé lo que vales”. No solía dar muchos consejos, pero era una persona muy
    cariñosa.</p>
  <p>Lo extraño todos los días y le pido a Dios que me lo devuelvan en vida. Siempre he tratado de aferrarme a la
    idea de
    que no lo voy a encontrar muerto, que un día me van a hablar de la fiscalía y que me van a decir “ya
    encontramos a
    Manuel, lo tenían en algún lado pero está bien”.</p>
<p class="text-center">✽✽✽</p>
  <p>Esto es algo muy difícil, yo puedo aceptar que las autoridades nos den la espalda… no que lo acepte, sé que
    es lo que
    hay. Pero cuando la misma familia te empieza a pedir que ya no busques, es muy difícil.</p>

  <p>Las compañeras sabemos que si nosotras no movemos un dedo, que si nosotras nos paramos, nadie los va a seguir
    buscando. De hecho yo sé que nadie los está buscando allá afuera, nosotras sabemos que tenemos que seguir en
    la lucha,
    haciendo lo que nos toca hacer, para obligar a las autoridades a que nos respondan como debe de ser.</p>

  <p>Y no soy la única, una compañera busca sola a su hijo, su marido dice que por ahí debe de andar y un día va a
    regresar. Como que las mujeres tenemos más la idea de que, si no nos ponemos a buscarlos, no van a
    regresar.</p>

  <p>Al principio me moría de miedo, no quería que nadie supiera que estaba en el colectivo, pero ahora me doy
    valor con
    mis compañeras.</p>

  <p>En este camino se van perdiendo amistades, se va perdiendo de todo. Una vecina me vino a decir que me iban a
    matar
    por andar buscando a mi hermano, que ya le parara, me insinuó que tenía miedo de vivir al lado de mi casa, por
    el
    peligro que represento por buscarlo. Yo no le expliqué nada, la gente no acaba de entender que ya nadie
    estamos
    exentos de que nos pase algo. Nadie, absolutamente nadie.</p>

  <p>También tenemos muchos gastos, se necesita dinero que no tenemos y sólo nosotras sabemos de dónde lo sacamos,
    o cómo
    ahorramos o qué dejamos de comprar para poder ir a las búsquedas. Yo estaba acostumbrada a tener un sueldo, a
    ser una
    mujer independiente y pues ahora dependo de mi pareja y tenemos otros gastos. Trato de ser cuidadosa y gastar
    lo
    mínimo, lo indispensable para las búsquedas, trato de ser ahorrativa para que no se vea afectada la casa.
    Encontrar
    trabajo en estos tiempos es difícil, hay gente que me dice que busque algo para trabajar en casa, pero no
    hay.</p>

  <p>Muchas están perdiendo su trabajo, hay ocho compañeras a las que ya les pusieron ultimátum, les dicen que o
    buscan a
    sus familiares o se ponen a trabajar, porque ya no van a dar permisos. A otras diez ya las corrieron.</p>

<p class="text-center">✽✽✽</p>
  <p>Navidad y Año Nuevo las pasábamos con Manuel, en su casa, era la tradición de todos los hermanos de reunirnos
    en el
    rancho, vernos ahí, comer y celebrar.</p>
  <p>Llegábamos temprano, las mujeres nos poníamos a cocinar. No escuchábamos música porque él siempre vivió en
    situación
    precaria. No tenía estéreo, sólo tele y sin internet. Entonces no era de poner música pero ni falta nos hacía,
    prendíamos una fogata, hacíamos tamales, atole… todo el día nos la pasábamos comiendo y en la noche íbamos de
    casa en
    casa a pedir dulces.</p>
  <p>Eso se acabó. Ahora cuando nos vemos o nos reunimos sólo hablamos de la desaparición de Manuel. A veces estoy
    platicando con mi mamá de cualquier cosa y de pronto ella empieza a llorar y me dice “ya quiero encontrar a tu
    hermano, ¿qué te han dicho?” … yo la veo mal, ella tiene diabetes, es hipertensa, ya está grande. Dice que no
    quiere
    morirse sin saber de él. Mis hijos y los otros nietos ven esos episodios. Yo sólo le digo: “mami, lo sigo
    buscando y
    un día lo vamos a encontrar”.</p>

  <p>Cuando hablo con mi hermana es lo mismo, me pregunta qué he sabido de Manuel, qué sé de tal fosa o de un
    cuerpo que
    encontraron, ese es nuestro tema de conversación. Con un familiar desaparecido ya no hay vuelta atrás, ya no
    puedes
    convivir en familia.</p>

<p class="text-center">✽✽✽</p>
  <p>Con la pandemia nos fue pésimo porque llegó justo cuando estábamos en las mesas de trabajo para que saliera
    la ley de
    desaparición en el Estado de Guanajuato. Todos los diputados habían dicho que querían conocer nuestras
    historias,
    nuestras necesidades. Fuimos a algunas reuniones y empezó la pandemia y ya no pudimos seguir. Dijeron que
    sacarían la
    ley sin nosotros pero tomándonos en cuenta. Salió la ley sin nuestras sugerencias.</p>

  <p>Tuvimos que hacer un plantón en plena pandemia porque pusieron como comisionado de búsqueda a un
    administrador de
    empresas, sin experiencia.</p>

  <p>En la fiscalía no nos atienden, ni en el SEMEFO, que solo con cita. Yo entiendo el tema de la pandemia pero
    ellos
    también deberían entender que nuestros desaparecidos están afuera, no sólo con riesgo del COVID, sino con
    riesgo de su
    vida. Siento que la pandemia ha sido utilizada como pretexto para no atender un problema que los gobiernos no
    quieren
    atender.</p>

  <p>Yo le tengo respeto al coronavirus, pero la pandemia no nos puede parar, porque es una desesperación diaria.
    Tener un
    familiar desaparecido es una enfermedad más grave que COVID, porque se te enferma el corazón, la mente, el
    ánimo… </p>
<p class="text-center">✽✽✽</p>
  <p>Yo sé que es complicado entenderme, en estos casi tres años he ido a muchas reuniones, salgo mucho, siento
    que
    descuido a mis hijos.</p>

  <p>Antes era una mujer plena… aunque la felicidad es efímera, una vive feliz cuando tiene a la familia completa.
    Desde
    que se llevaron a Manuel vivo con miedo, no hay día que no sienta ese vacío… A veces cuando estoy comiendo o
    cuando me
    voy a dormir, me da culpa de que no estoy en la calle buscándolo y yo estoy muy a gusto y no sé si él está
    sufriendo o
    qué estará pasando o qué le estarán haciendo.</p>

  <p>Con mis búsquedas quiero enseñarles a mis hijos el amor de hermanos, porque si un día a uno le pasa, el otro
    lo va a
    buscar, entre los dos deben cuidarse. Yo sé que si yo me hubiera perdido, mi hermano hubiera hecho lo mismo
    por
    mí.</p>

  <p>Aunque no he regresado a estudiar o a trabajar siento que no he hecho sacrificios por mi hermano, simplemente
    son
    prioridades: concentrarme en el colectivo y ayudar a la gente a que sus familiares regresen a sus casas,
    porque sé lo
    que se siente.</p>

  <p>El miedo a veces me impide disfrutar la vida, mis hijos no van a crecer como yo crecí, jugando en las calles
    con los
    amigos. Tienen prohibidísimo salir a la banqueta si no estoy yo, no pueden despegarse de mí ni un momento. Y
    eso les
    afecta en su desarrollo, veo que son cohibidos, no conviven si no estoy yo, pero prefiero eso a que un día les
    pase
    algo y no sepa de ellos.</p>

  <p>Me encantaría que mi hermano conozca a mi hijo chico, a veces sonrío pensando el apodo que le pondría. Me
    duele mucho
    que mi hijo el grande ya no se acuerde de él, tiene 7 años y son casi tres años que no lo ve. Y yo le cuento
    que tiene
    un tío que lo amaba mucho y que a mi me chiqueaba… les hablo mucho de él.</p>

  <p>Un día en la escuela a mi hijo le preguntaron a qué se dedicaba su mamá y él dijo: “a buscar a su hermano”.
    Yo me
    puse a llorar cuando la maestra me contó, porque yo sé que mis hijos no están para andar en las
    manifestaciones o en
    las marchas. El otro día estuvieron conmigo cinco horas en la procuraduría de derechos humanos porque fui a
    poner una
    queja. Sé que los he traído a lugares en los que niños de su edad no deben andar, pero lo debo buscar.</p>

  <p>Yo sé que en el camino voy a encontrar a mi hermano y lo voy a regresar a casa y un día veremos esto como un
    mal
    recuerdo.</p>
  <img src="assets/bibiana/4.jpeg" class="img-fluid my-5" alt="">

  <p>Lo que nosotras queremos es que se les busque vivos y de inmediato. Queremos que sean transparentes con las
    fosas, a
    principio de año encontraron una muy cerca de donde desapareció mi hermano, nunca dijeron cuántas personas se
    encontraron ahí y en la Fiscalía dicen que las fosas en la ley no existen.</p>

  <p>Hace poco les llamaron a 5 compañeras para ir a Santa Rosa de Lima a hacer una búsqueda acompañadas por la
    policía
    estatal. Decidimos que no irían porque era en el lugar más peligroso de Guanajuato, con la policía estatal que
    son los
    que nos han acosado, nos han perseguido, decidimos que no, porque son a los que les tenemos miedo. Luego vimos
    que
    hubo un enfrentamiento ahí, hubo personas muertas. Nosotras no podemos darnos el lujo de morirnos porque si
    nos matan,
    ¿quién los va a buscar?</p>

  <p>Les hemos pedido a las autoridades que vayan a algunos puntos de búsqueda que tenemos ubicados, pero nos
    ignoran. Hay
    compañeras que se han ido a meter a comunidades bastante peligrosas a preguntar, la verdad sí da miedo irse a
    meter a
    esos lugares, la policía sabe dónde están las fosas pero nunca nos lo van a decir.</p>

  <p>Ahora que fuimos a las búsquedas en campo y encontramos cuerpos, no sabía si era mi hermano, pero sabía que
    esas
    personas iban a regresar con sus familias y ya podrían estar en paz. Y yo sé que quizá un colectivo hará lo
    mismo en
    otra parte y quizá encuentren a mi hermano y me darán la paz que tanto necesito y el descanso que mi hermano
    se
    merece.</p>

  <p>La fiscalía me asignó una psicóloga pero me insinuaba que no era adecuado andar en el colectivo, que quizá lo
    más
    conveniente era “soltar”. No regresé, no me dio confianza. A mí me sirve mucho cuando salimos a protestar y
    gritamos,
    o cuando hablamos entre nosotras; cuando encontramos a algún familiar desaparecido, aunque la mayoría han sido
    muertos…</p>

  <p>Lo que queremos es que nos traten con dignidad, queremos ayudar a más personas, gané una familia hermosa con
    mis
    compañeras, yo las admiro mucho, me dan mucha fortaleza. Odio las leyes, pero he tenido que leerlas y aprender
    para
    ver si lo que pedimos es viable ¡y sí, es viable!</p>
  <img src="assets/bibiana/5.jpeg" class="img-fluid my-5" alt="">

  <p>En todas las dificultades que he tenido en mi vida, desde que estaba chiquita, el amor es lo que nos ha
    ayudado.
    Cuando yo veo que pasábamos hambre, que en verdad vivíamos en pobreza, aún trayendo los zapatos rotos, yendo a
    la
    escuela con mochilas que le regalaron a mi mamá, el amor me rescató de todo eso. Ahora ya de grande me doy
    cuenta de
    la pobreza en la que vivíamos, pero el amor de mis hermanos y de nuestra madre nos hacía felices.</p>

  <p>Y ese amor es el que me impulsa a buscar a mi hermano: el amor que le tengo y el amor que él me dio. Yo lo
    voy a
    buscar hasta que me muera, al igual que mi mamá, siempre le pido a Dios que no me lleve de esta tierra hasta
    saber qué
    fue de mi hermano.</p>

  <p>Desde que se llevaron a Manuel siento que no estoy completa, que algo me falta y aunque esté feliz con mis
    hijos o
    con mis amigas, tengo un vacío que me ahoga. El no saber si lo están haciendo sufrir, o si lo hacen creer que
    ya nadie
    lo está buscando, o si en verdad está muerto y lo dejaron ahí tirado como cualquier cosa, eso es algo que no
    tolero. </p>

  <p>Yo lo voy a seguir buscando.</p>

  <div class="intro-container black d-flex justify-content-center my-5">
    <div class="col-md-3 align-self-center">
      <img src="assets/bibiana/silueta.png" alt="" class="img-fluid">
    </div>
  </div>

</div>
