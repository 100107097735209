<div class="intro-container black d-flex justify-content-center">
  <div class="col align-self-center">
    <img src="assets/portadas/mariana-d.jpg" alt="" class="img-fluid d-none d-md-block">
    <img src="assets/portadas/mariana-d.jpg" alt="" class="img-fluid d-block d-md-none">
  </div>
</div>
<div class="container">
  <app-sharers
    fb="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuscadoras.poplab.mx%2Fmariana"
    tw="https://twitter.com/intent/tweet?text=Cuando%20ellas%20buscan%3A%20mariana&url=https%3A%2F%2Fbuscadoras.poplab.mx%2Fmariana&via=poplabmx"
    wh="https://wa.me/?text=Cuando%20ellas%20buscan%3A%20Mariana%20https%3A%2F%2Fbuscadoras.poplab.mx%2Fmariana%20"
  ></app-sharers>
  <h5>
    <i>
      Yo no tengo por qué esconderme, he hecho las cosas bien. Si hay alguien que no ha actuado bien, son las
      autoridades
    </i>
  </h5>
  <p>Por: <b>Alonso Merino Lubetzky</b></p>

  <img src="assets/mariana/1.jpg" class="img-fluid my-5" alt="">

  <p>Alberto y yo éramos así...uno. Aunque fuera a la distancia, aun lejos el uno del otro, siempre sabíamos de
    nosotros.
    No había día que no le mandara mensajes y si veía que no me contestaba le marcaba de mi celular o del teléfono de la
    casa. Con que respondiera, con solo verlo &quot;en línea&quot;, me sentía tranquila. Diario le daba los buenos días
    o
    su bendición en la noche. Así fue hasta que lo desaparecieron y me destruyeron por completo.</p>
  <p>Mi hijo estaba en Torreón trabajando y reconciliándose con su padre. Siempre le dije que quería que se alejara de
    los
    vicios y accedió a irse para empezar de nuevo. Se fue justo cuando el crimen empezó en Guanajuato. Sentía terror,
    pánico, de imaginar que algo le llegara a suceder.</p>
  <p>Estábamos en plena contingencia cuando le propuse que él y Selene, su novia, se vinieran dos semanas al pueblo, a
    Silao. Una noche antes de que partieran rumbo a Torreón tres hombres armados se lo llevaron. Era 20 de julio. Lo
    último que dijo fue que iba a comprar de cenar. Veinticuatro días estuvo desaparecido, veinticuatro días lo
    busqué.</p>
  <p class="text-center">✽✽✽</p>
  <p>Yo nací aquí en Silao, Guanajuato, pero mis mejores recuerdos son con mis hermanos y mis abuelos, cuando nos íbamos
    al rancho. Era tan bonito andar allá que no nos queríamos regresar. En ese rancho nació mi mamá: San José de Gracia,
    se llama. Le llorábamos para no venirnos. Era muy libre. La casa de mis abuelos es grande, tiene árboles frutales y
    está a la orilla del río.</p>
  <p>Era donde nos dábamos la gran vida. Ahí tenían los carritos de mulas, esos en los que se vende fruta, y nos
    encantaba
    andar trepados con mis tíos. Son carros con un caballo o dos mulas adelante. Esos son de los momentos más bonitos
    que
    vivimos porque mis abuelos de allá eran bien diferentes a los de aquí. Los de Silao eran muy duros, muy pegones, y
    allá en el rancho no. Eran diferentes: muy amorosos.</p>
  <p>Soy la más chica de cinco mujeres y un hermano. A pesar de que tuve a un papá muy machista, alcohólico y golpeador,
    mi niñez fue una de las etapas más bonitas de mi vida. Hubo un tiempo que viví con un hermano de mi mamá aquí en
    Silao. Me iba con mi tía, su esposa, porque como no tuvo hijos me invitaba a ayudarla a vender muebles, pero también
    porque quería alejarme de todo lo que estaba viviendo en mi casa. Toda la semana yo salía de la escuela y me iba
    directo con mi tía, salvo los fines de semana. Nunca me fui del todo porque yo sabía que se llegaba el fin de semana
    y
    mi papá iba a tomar. Yo quería estar ahí para cuidar a mi mamá.</p>
  <p>Cuando cumplí 15 años me fui a vivir a Ciudad Juárez, pero me fui yo sola, sin mi familia. Estaba en la secundaria
    y
    la dejé para irme a trabajar. Al principio estaba en una tienda de ropa, pues como era menor de edad, no podía
    entrar
    a una empresa formalmente. Me fui sin avisar, allá me recibió la familia que tengo por parte de mi mamá.</p>
  <p>No sé cómo pude tener el valor para hacer eso, porque me fui en el tiempo en el que estaba más fuerte el
    feminicidio,
    en el que había muertas todos los días. Loca, tomé esa decisión tan arrebatada, y hui. Mis papás estaban enojados y
    aunque me pedían que regresara yo les decía que no, pero siempre me pregunté por qué no fueron a buscarme. Ellos
    podían hacerlo, sin embargo, no lo hicieron.</p>
  <p>Fui diferente con Alberto. Hice muchas cosas por él, pues lo buscaba incluso antes de que desapareciera. Yo quería
    lo
    mejor para él y para mis hijas, y por eso siempre andaba detrás suyo. Si a mí me preguntan hoy en día que si tengo
    remordimiento en mi relación con él, les diría que no, pues sé que hice más de lo que estaba en mis manos. Hay gente
    que
    no sabe o que no tiene la mínima idea. Todas las veces que yo lo metía a un anexo o a una clínica, él me decía que
    no
    le gustaba, pero me decía que me amaba, que me lo agradecía.</p>
  <p>Al papá de Alberto y de mis hijas lo conocí en Juárez. Él trabajaba en una empresa japonesa de plásticos a la que
    me
    ayudaron a entrar con una carta, pues era menor de edad. Pasó el tiempo y nos juntamos, luego vinieron los hijos. En
    Ciudad Juárez nacieron los más grandes, mi hija la mayor y Alberto; allí estuve como siete años. Después me fui a
    Torreón donde nació mi hija la más chica.</p>
  <p>Ya siendo mamá, ya con hijos, comienzo a ver realmente la situación. Estando chica no percibía la inseguridad. Y
    entonces fue que me dije a mí misma que no quería que mis hijos crecieran en ese ambiente. Ciudad Juárez es horrible
    en todos los aspectos, tanto en el clima como en la violencia. Si tú vas sigues viendo papeles pegados de mujeres
    desaparecidas.</p>
  <p>Nos mudamos a Torreón porque el papá de mis hijos es de ahí. Para entonces ya teníamos problemas, tal vez era la
    diferencia de edades entre el señor y yo, quien es 11 años más grande. Era muy posesivo y después agarró amistades
    que
    a mí no me gustaban y aumentaron los conflictos.</p>
  <p>Al principio era como cualquier papá, miraba por sus hijos, pero luego fue cambiando mucho. Al tiempo de que lo
    conozco, me embarazo y me salí de trabajar. Él ganaba muy bien en el trabajo. Pero, así como ganaba bien, era muy
    mujeriego. Tomaba más de lo normal y consumía sustancias. Se volvió irresponsable, y desde ahí empezamos a
    distanciarnos. Salía de trabajar el viernes y llegaba hasta el sábado, pero ya llegaba sin un peso. Entonces, ¿qué
    tenía que hacer yo? Pues trabajar. Por eso estando en Torreón trabajé en varias empresas automotrices.</p>
  <p>El papá de Alberto fue violento físicamente conmigo. Aunque solo una vez me golpeó, con esa vez tuve. Me amenazaba
    constantemente y nunca quiso ayuda de nadie con sus problemas de adicciones. Conforme su adicción crecía, su
    violencia
    también. La vez que me golpeó me mandó al hospital. Me golpeó a puño cerrado dejándome inconsciente tirada en un
    charco de sangre. Coincidió que mi hermana estaba allá y me dijo que abriera los ojos, que no era posible que yo
    soportara eso. Eso pensé, que no tenía por qué estarlo aguantando.</p>
  <p>No era responsable de sus hijos y no me ayudaba económicamente. Se volvió la peor persona, ya no trabajaba, ya no
    aportaba nada. Solo pensaba en el vicio. Tenía una mujer que le compraba droga para que estuviera con ella, y eran
    todo ese tipo de cosas que yo tenía que aguantar. Yo decía &quot;como para que ande con esa persona, entonces yo
    quién
    soy o qué soy&quot;, al grado de que se me bajaba la moral. Desde que nos separamos, yo estando allá, tuve que
    trabajar sola para salir adelante.</p>
  <p>Tal vez era miedo de que me hiciera algo más. Llegó un momento en que me dije que eso no podía continuar, que no me
    podía quedar ahí, que tenía que hacer algo.</p>
  <p>De vivir en el norte del país me queda la violencia, que ahora es la misma en Guanajuato. Torreón estaba igual que
    Juárez: muchísima inseguridad, muchos muertos por donde quiera. En las escuelas de Torreón amenazaban a los
    maestros,
    les iban y les cobraban cuota y amenazaban que si no pagaban que les pedían se llevarían a ciertos niños y a los
    maestros. Y sí lo llegaron a hacer. La mejor opción fue regresarnos a Silao con mi familia. Yo pensé que ya no tenía
    nada que hacer ahí, pues ya para entonces no estaba con el papá de mis hijos.</p>
  <p>Al tiempo de haber regresado a Silao conocí a mi pareja actual, Luis. Nos empezamos a tratar y tenemos juntos desde
    entonces. Él tiene una niña también y vive con nosotros. Desde que nos conocimos él me ayudó con mis hijos y yo con
    su
    niña. Luis también siempre fue muy cercano a Alberto. Seguido los encontraba platicando mientras miraban la
    televisión.</p>
  <img src="assets/mariana/2.jpg" class="img-fluid my-5" alt="">
  <p>Siempre he querido mucho a mi mamá. Siempre. Para mí ha sido lo mejor que me ha pasado. Para mí es una mujer
    admirable porque nunca nos dio un mal ejemplo. Nunca. Sufrió mucho y yo también por ella. Te quedas marcada. Alberto
    también la quiso mucho, donde quiera que la viera, la abrazaba y la besaba siempre. Con su abuelita también era muy
    allegado, por eso antes de irse a Torreón, antes de que lo desaparecieran, en el último lugar en el que quiso estar
    fue en su casa.</p>
  <p>Ella viene de una familia en la que mi abuelo tenía tierras, sembraban. No le faltaba nada, tenía todo lo
    necesario.
    Se casó de 28 años. Ella dice que sus hermanos no la dejaban tener novio y por eso se fue con mi papá a escondidas,
    porque realmente se la pasaban cuidándola. Obviamente si hubieran visto al señor con quien se iba a ir no la
    hubieran
    dejado.</p>
  <p>Mi hija le pregunta a su abuela por qué cambió su vida para irse a meter a una casa donde la golpeaban y le hacían
    de
    todo cuando tuvo una vida muy buena con sus papás. Cuando se vino del rancho a Silao hasta sus propios suegros la
    trataban mal. Fue totalmente un cambio porque acá mi papá era tomador, mujeriego, golpeador. Igual, si se le
    antojaba
    se gastaba todo el dinero y ya no daba para la semana.</p>
  <p>En muchas ocasiones la dejaba embarazada y se iba a Estados Unidos y se quedaba con otras mujeres. Mi mamá siempre
    ha
    sido de las personas que decía que, si tú te casas, te casas para toda la vida. Siempre que le pregunto por qué,
    ella
    contesta que era su cruz. Le decía que no, que no era cierto, no tiene por qué una estar aguantando golpes.</p>
  <p>Cuando mi papá murió le dijimos que se casara de nuevo. Y dijo que no. Yo creo que mi madre empezó a tener vida
    después de que mi papá falleció. El 18 de octubre cumplió 10 años de muerto. Casarse con él fue lo peor para ella.
    Su
    matrimonio duró treinta y tantos años, y ella va a cumplir ochenta.</p>
  <p>Tal vez por eso yo nunca me casé. En mi casa con mi mamá era de que a fuerza me tenía que casar, por la iglesia y
    por
    el civil. Todas mis hermanas así lo hicieron. Todas menos yo. Con ninguno me casé.</p>
  <p>Recuerdo mucho cuando estaba en sexto de primaria. Cuando mi papá tomaba, comía mucho. Todo el día quería estar
    comiendo y le exigía a mi mamá que le sirviera de comer. &quot;No, sírveme otra vez, porque no me has dado&quot;, le
    decía mi papá. Fue un domingo y ese día había hecho caldo cocido. Cuando ella le sirve un plato, agarra y dice:
    &quot;Mmm,
    ni para caldo eres buena&quot; y se lo aventó. Mi mamá se quedó asustada. Agarró una madera y se le dejó ir para
    golpearla. Le grité que no le pegara y me puse enfrente de ella. El golpe que le iba a dar, me lo dio a mí en la
    cabeza. Él se fue y me quedé escurriendo de sangre.</p>
  <p>Cuando me fui a Ciudad Juárez fue lo peor dejar a mi mamá. Después me arrepentía de haberme ido. Te das cuenta que
    dejaste tu hogar, que las cosas no son como te las platicaron. Es doloroso dejar a tu familia atrás, pero lo que más
    me dolió fue dejarla a ella.</p>
  <p>Cuando llegué a Silao con ella, llegué como desorientada. Estaba acostumbrada a vivir en otra ciudad más grande.
    Pero
    me sentí tranquila de vivir aquí. Entonces no había peligros, era muy calmado todo. Ese cambio fue muy radical, pero
    yo vi a mis hijos contentos. </p>
  <p class="text-center">✽✽✽</p>
  <p>Llegando a Silao estuvimos un tiempo en casa de mi mamá. Estaba encantada porque estábamos juntas. Mi hermano vive
    ahí con ella, nunca se casó y no tiene hijos. Si hacían alguna travesura, él se enojaba. Los amenazaba con pegarles
    y
    yo lo confrontaba. &quot;A mis hijos no los vas a tocar&quot;, le decía. Como me sentía harta de esas amenazas y no
    quería que nada le pasara a mis hijos, decidí salirme también de ahí.</p>
  <p>Por eso, y como no pensaba pasarme toda la vida con mi mamá, me metí a trabajar al Parque Bicentenario cuando lo
    abrieron. Trabajaba como cajera y los horarios se me acomodaban. Rentamos un departamento, ahí teníamos más
    privacidad. Mi hija se graduó de la primaria y entró a la secundaria. Cambié a mis hijos más cerca. Ellos se iban
    caminando a la casa de su abuelita cuando salían de la escuela y yo los recogía al terminar el día.</p>
  <p>De pronto me quedé sin trabajo. Entonces entré a una empresa en Puerto Interior donde se hacen motores para los
    limpiaparabrisas de los carros. Estuve casi dos años ahí. Al tiempo me salí porque Alberto se estaba haciendo pato
    en
    la secundaria. Los maestros me llamaron y me dijeron que Alberto sí llegaba a la escuela, pero que no entraba a los
    salones.</p>
  <p>Me di cuenta que necesitaba atención. Y un lunes me pregunta Alberto: &quot;¿Por qué no vas a ir a trabajar?&quot;
    --&quot;Porque
    tengo un niño de kínder que tengo que llevar a la secundaria para que entre al salón&quot;. --&quot;No es cierto, sí
    entro&quot;, dijo. -- &quot;No, niño --le dije--, yo no voy a estar pagando para que usted se quede en el patio. Yo
    pago para que usted ocupe la silla y se ponga a estudiar como es. Así es de que vámonos, lo voy a llevar y voy a
    hablar con sus maestros&quot;.</p>
  <p>Alberto creció y comenzó a tener problemas de adicciones. Yo quería que fuera una persona de bien, que saliera de
    eso. Nunca me iba a resignar a que estuviera perdido en algún vicio. No tengo ni el mínimo remordimiento ni siquiera
    de las veces que lo reprendí porque sé que él siempre estuvo consciente que fue por su bien. Como madre uno lo tiene
    que hacer. Nunca le solapé nada, en ninguno de los aspectos.</p>
  <p>Iba a ser su cumpleaños, tenía 14, y se me salió con unos amiguillos. Me pidió permiso para dormir en casa de un
    amigo. Se lo negué, le di solo permiso de salir con ellos, pero no de dormir afuera. Cuando fuimos a buscarlo, no lo
    hallé. Llegué a la casa y le marqué de nuevo. &quot;No soy tu juguete. ¿Dónde estás?&quot;, le dije. &quot;No te doy
    permiso&quot;. Pero insistió que ahí estaba y ahí voy de nuevo. Luis me llevó por él en el carro y cuando se sube
    nos
    percatamos que olía a alcohol. Apenas llegamos a la casa le pedí que subiera a la habitación. &quot;¿Qué pasó,
    Alberto? A ver, ¡sóplame!&quot; Le di un cintarazo, lo regañé. Me pidió perdón, me dijo que sus amigos lo
    convencieron
    porque era su cumpleaños. Fue la primera y la última vez que le pegué, pero cuando me fui a trabajar me sentía súper
    mal.</p>
  <p>Mi momento más feliz con él fue cuando lo llevaba a la escuela, en las vacaciones, cuando iba a sus festivales.
    Cuando veíamos películas, cuando íbamos al cine. Cuando se graduó de la primaria, no le busqué madrina. Yo fui su
    madrina para bailar su vals con él. Hay un video donde estamos bailando y está llorando porque se estaba despidiendo
    de sus amigos. Yo sabía que era muy noble y sentimental. Lo abracé y le dije de cerca que sí, que ya no iba a ver a
    sus amigos, pero que comenzaba una nueva etapa y que así tenía que ser.</p>
  <p>Cuando nos llegamos a ir a Guayabitos estábamos por un camino rumbo a Los Ayala. Nos fuimos caminando y el paisaje
    estaba muy bonito por toda la carretera. Él sabe que yo odio las lagartijas, las iguanas y todo eso. Me dan mucho
    pánico. Ese día ya veníamos y sale corriendo con una iguana enorme. &quot;¡Te la voy a echar!&quot;, me dijo. &quot;Dame
    500 pesos, si no te la voy a echar encima&quot;. Yo grité. Le pedí ayuda a mi mamá porque pensé que estaba viva. Se
    la
    encontró atropellada. &quot;Pinche niño&quot;, le dije. Se murió de risa porque yo me asusté.</p>

  <img src="assets/mariana/3.jpg" class="img-fluid my-5" alt="">

  <p>Alberto vivía en Torreón. Le recomendé que se fuera de aquí para protegerlo, pues la violencia crecía en el pueblo.
    Yo pensé que le servirían los consejos de su papá, el hecho de que regresara y lo viera, que empezaran a platicar y
    arreglaran todos sus problemas. Las hermanas del papá de Alberto me habían dicho que ya estaba cambiado. Ciertamente
    me dolía mucho que se fuera, y aunque se me rompía el corazón porque nunca nos habíamos separado, lo veía bien
    allá.</p>
  <p>Pero su papá y un amigo suyo lo habían golpeado días antes de que regresara a Silao. Acordamos que él y su novia se
    vinieran por la pandemia, porque económicamente se estaba poniendo difícil. Alberto me contó que llegaron él y su
    novia a casa de su papá y lo encontraron drogado con un amigo. Se hicieron de palabras por algo de la casa y su papá
    y
    su amigo, los dos, se le fueron encima a golpes. El amigo de su papá le pegó con un envase de cerveza y quedó todo
    cortado del brazo. Hasta la fecha yo sigo enojada con su padre por eso. No se lo perdono.</p>
  <p>Estando acá ya en Silao, Alberto me dijo que no se iban a quedar mucho tiempo. Selene y él llevaban poco de haberse
    juntado. Le dije: &quot;Vente y se quedan quince días&quot;. El plan era que cuando regresaran a Torreón, llegarían
    a
    vivir a casa de una tía y pondrían un taller de motos. Él había trabajado en uno y queríamos que abriera el suyo
    allá.
    Yo lo veía que estaba saliendo adelante.</p>
  <p>Estaban por cumplir un mes aquí cuando tuvimos nuestra última conversación. Fue un sábado en casa de mi mamá. Me
    dijo
    que el lunes se irían de regreso a Coahuila. Estaba en casa de su abuelita porque se estaba despidiendo y fue cuando
    me platicó que había tenido un problema con una persona que vendía droga.</p>
  <p>El domingo fui de nuevo a casa de mi mamá y los vi a él y a mi nuera. Al día siguiente, el lunes, pedí permiso para
    ausentarme porque quería despedirlo. A las 12:00 del día llegué a casa de mi mamá y lo primero que me dicen es que
    Alberto no había llegado en toda la noche. Les había dicho que iba a ir a comprar cena, pero ya no regresó.</p>
  <img src="assets/mariana/4.jpg" class="img-fluid my-5" alt="">
  <p>Lo primero que hice fue ir a hospitales. Luego al <em>Pentágono</em> --así le llaman al edificio de Seguridad
    Pública
    en Silao-. Fui varias veces ese mismo día, pero no me dejaban entrar a barandilla. No era la primera vez que Alberto
    no llegaba y podría estar ahí. Una vez hasta se cambió el nombre y por eso yo les pedía que me dejaran ver, porque
    su
    nombre no aparecía en la lista. Entonces salió un hombre a quien lo habían arrestado por tomar. Saco una foto y se
    la
    muestro. Me dijo que no había nadie como él adentro.</p>
  <p>El tiempo estaba corriendo y yo no sabía nada. Le dije a Selene que me iba a regresar porque tenía que darle de
    comer
    a mis hijas. Pasó el lunes, se hizo de noche y mi hijo tampoco llegó. Volví a ir más tarde al Pentágono, pero ya
    estaba oscuro. Les pedí que si me dejaban entrar y tampoco tuve suerte. Me dijeron que podía ir al día siguiente a
    las
    8:00 de la mañana, cuando hay cambio de juez. Tampoco pude entrar.</p>
  <p>Fui al Ministerio Público como a eso de las 3 de la tarde del martes y pongo la denuncia por desaparición. Me
    tomaron
    algunos datos. En un primer momento no me pidieron sus características personales. No me preguntaron cuánto medía,
    qué
    tatuajes tenía, qué ropa llevaba. Nada. Solamente su nombre, mi nombre, dirección y la última vez que se le vio.</p>
  <p>Estaban mis hermanas en mi casa y llegó la persona sospechosa de su desaparición. Aseguró que tres hombres armados
    llegaron en un carro gris, que estaba sentado frente a su casa cuando vio que se lo llevaron. Me cayó un balde de
    agua
    fría. Las versiones sobre la desaparición de Alberto cambiaron cada vez. Ellos las cambiaron. A mi nuera esta
    persona
    le dijo que Alberto estaba en su casa y que los hombres armados habían entrado por él. Unos días antes de que
    encontrara a mi hijo, él mismo dijo que Alberto les había hablado por teléfono para irse con ellos.</p>
  <p>Estuvo casi un mes desaparecido, veinticuatro días. Cuando iba al Ministerio Público o me mandaban llamar, me
    decían
    que no sabían nada. Cuando me enteraba de algo acudía al MP para decirles lo que sabía, para aportar lo poco que
    podía, pero siempre me decían &quot;que yo qué sabía&quot;. Realmente no sabemos a quién irle a confiar lo que uno
    sabe.</p>
  <p>Lo último que hizo el Ministerio Público fue venir hasta mi domicilio. Entraron los ministeriales sin identificarse
    mientras yo no estaba en casa. Mi nuera les abrió y se pasaron. Nunca pude saber quiénes eran porque no llevaban
    nada
    visible: ni placa, ni nada. Nos pidieron llevarlos a la casa donde Alberto desapareció. Con desconfianza nos subimos
    a
    la camioneta mi nuera y yo.</p>
  <p>Hicieron un cateo para ver si podían encontrar las pertenencias de mi hijo, pero nunca se cercioraron de que el
    sospechoso estuviera ahí. Otras personas me dijeron que iba llegando cuando vio a los agentes haciendo el cateo. Me
    llamaron al MP y sus últimas palabras fueron que ya no había nada qué hacer. Me notificaron que la carpeta de
    investigación se iba a Irapuato.</p>
  <p>En esas fechas habían encontrado fosas y yo ya estaba en los colectivos de búsqueda, por eso supe. Mi colectivo es
    &quot;De Pie Hasta Encontrarte&quot;. Selene, la novia de mi hijo, se fue conmigo a Irapuato. No sabíamos llegar,
    pero
    igual tomamos un camión bajando de la central de autobuses. Les pedí que me orientaran para llegar al CERESO y al
    SEMEFO.</p>
  <p>Llegando al Servicio Médico Forense uno de los agentes en la puerta me pidió el número de carpeta de investigación.
    Se lo doy. Yo quería ver si me podían enseñar las fotos de las personas no identificadas. Me pregunta qué relación
    tiene la persona desaparecida conmigo. --&quot;Es mi hijo&quot;, le dije. Tomó el papel donde llevaba anotado el
    número de carpeta y se fue. A los pocos minutos apareció de nuevo.</p>
  <p>Nos dijeron que nos dirigiéramos al edificio de Homicidio. Me registro y me piden que me pase. Cruzo la puerta de
    arriba y salen agentes de investigación, quienes nos dijeron que nos llevarían a ver las fotos. Nos subimos a su
    camioneta y ya arriba una agente se me queda viendo. Saca su teléfono y empieza a pasar fotos. Me mira de nuevo y me
    pregunta por la ropa de Alberto, por sus tatuajes. Me pidió tranquilizarme y me mostró las fotos. Era él.</p>
  <p>Su respuesta fue que el mismo domingo que desapareció lo encontraron entre las 11 y 12 de la noche por el parque
    industrial Castro del Río. Estuvo casi un mes en SEMEFO, con todo y que les proporcioné fotos. Todo.
    Características.
    Todo. Pruebas de ADN. No entiendo cómo, si tenían mis pruebas de ADN y las de mi hija, en ningún momento nos
    avisaron
    que estaba ahí.</p>
  <p>En ese momento me pidieron su acta de nacimiento, pero no la llevaba. Llamé a mis familiares para que me la
    llevaran.
    Finalmente liberaron el cuerpo, así que partimos rumbo a SEMEFO. Me dijeron que empezara los trámites con la
    funeraria
    para que lo recogieran. Cuando llegué y pasé a firmar los papeles que me pedían, me negaron el cuerpo de mi hijo una
    vez más: me faltaba una copia. A esas horas de la noche no había ni un solo lugar dónde sacar una copia, además de
    que
    no estaba la trabajadora social para firmar.</p>
  <p>Tuve que regresar al día siguiente antes de las 9 de la mañana. Cuando terminé los trámites me pidieron pasar a
    reconocer a Alberto. Antes, cuando lo estaba buscando, le había dicho a mi hermana que si algo malo pasaba ella
    debería reconocerlo por mí, porque yo no tenía el valor.</p>
  <p>Tuve que hacerme más fuerte porque solo yo tenía permiso para entrar. Su cuerpo ya estaba en estado de
    descomposición, pero todavía se alcanzaban a ver sus facciones. El cuerpo se lo llevó la funeraria y por instantes
    me
    quisieron negar también el velatorio &quot;porque ya tenía muchos días allá&quot;, según dijeron. Insistí que para
    esa
    noche y al día siguiente lo sepultamos.</p>
  <p>Durante casi un mes dormí en el sillón de la sala. En las noches esperaba despierta que tocara la puerta y entrara.
    Hasta el momento no sé lo que pasó. La única versión que existe es que fue por 200 pesos de marihuana. No me cabe en
    la cabeza que eso valiera su vida.</p>
  <p>La ropa de Alberto la conservaba toda hasta hace unas semanas. Poco a poco la he ido regalando como él de por sí lo
    hacía con sus amigos. Cuando los veía sin tenis o sin algo para vestir, él se los daba a escondidas. Ahora solo
    guardo
    algunas cosas en su clóset.</p>
  <p>Siempre se los dije, incluso se lo dije a la persona que está detenida: yo estoy segura que mi hijo no tenía nada
    que
    ver. Y yo tampoco, porque soy una de las personas que se levanta día a día a trabajar, y a trabajar honestamente
    para
    sacar a mi familia adelante. Yo no tengo por qué esconderme, porque yo he hecho las cosas bien. Si hay alguien que
    no
    ha hecho las cosas bien, son ellos. Eso siempre le dije a mi familia: yo no tengo miedo, porque yo no estoy haciendo
    nada equivocado. Lo busqué sin parar hasta encontrarlo.</p>
  <p>Y lo encontré, pero no se ha hecho justicia.</p>
  <img src="assets/mariana/5.jpg" alt="" class="img-fluid my-5">
  <p><em>*Mariana es el nombre ficticio que se dio a la buscadora por seguridad</em></p>
  <div class="intro-container black d-flex justify-content-center my-5">
    <div class="col-md-3 align-self-center">
      <img src="assets/mariana/silueta.jpg" alt="" class="img-fluid">
    </div>
  </div>
</div>
