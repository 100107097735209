<div class="intro-container black d-flex justify-content-center">
  <div class="col align-self-center">
    <img src="assets/portadas/matilde-d.jpg" alt="" class="img-fluid d-none d-md-block">
    <img src="assets/portadas/matilde-m.jpg" alt="" class="img-fluid d-block d-md-none">
  </div>
</div>

<div class="container">
  <app-sharers
    fb="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuscadoras.poplab.mx%2Fmatilde"
    tw="https://twitter.com/intent/tweet?text=Cuando%20ellas%20buscan%3A%20Matilde&url=https%3A%2F%2Fbuscadoras.poplab.mx%2Fmatilde&via=poplabmx"
    wh="https://wa.me/?text=Cuando%20ellas%20buscan%3A%20Matilde%20https%3A%2F%2Fbuscadoras.poplab.mx%2Fmatilde&via=poplabmx%20"
  ></app-sharers>
  <h5>
    <i>
      &quot;Lo encontré sin la ayuda de ninguna autoridad, solo el apoyo de mi familia y mis compañeras del
      colectivo al que me uní cuando me di cuenta que la Fiscalía no iba a hacer nada.&quot;
    </i>
  </h5>

  <p>Por:<strong> Carmen Pizano</strong></p>

  <img src="assets/matilde/1.jpg" class="img-fluid my-5" alt="">

  <p> A pesar de que tuve una infancia con muchas carencias en cuestión de ropa, de comida, que no teníamos regalo
    de Día de
    Reyes, regalos de Navidad, vivía feliz con mis hermanos. La vida me obligó a madurar muy rápido, de golpe: fui
    para
    mis hermanos menores como una mamá, poco antes de cumplir mis 15 años me enteré que estaba embarazada, fue una
    pesadilla vivir con el papá de mi hijo, pero luego conocí a Fernando, mi esposo.</p>
  <p>Con Fernando todo era risa, con él nunca escuchamos gritos, nunca peleas. Siempre trataba de hacernos ameno el
    día,
    de vernos reír.</p>
  <p>Nos encantaba ver el amanecer, cuando íbamos en carretera nos parábamos. Yo no conozco la playa, por eso él
    siempre
    me proponía irnos a la aventura, nunca lo hicimos. Me decía que cuando estuviera viejo nos fuéramos a vivir a
    una
    costa porque él se quería morir en el mar.</p>
  <p>La vida tranquila con Fer se acabó cuando se lo llevaron hombres armados en Silao, muy cerca del Pentágono,
    como se
    conoce a los separos municipales, a la vista de policías que nada hicieron para detener el vehículo al que lo
    subieron.</p>
  <p>El 19 de julio pasado una niña se quedó sin papá, yo me quedé sin esposo, una madre se quedó sin hijo, es algo
    que
    esas personas no ven. No miden el daño tan grande que le causan a la gente.</p>
<p class="text-center">✽✽✽</p>
<p>Soy la mayor de cinco hermanos, desde que era pequeña cuidaba de ellos, principalmente del &quot;Huevo&quot;,
    así le
    decíamos al hermano hombre que sigue de mí. Yo lo defendía cuando lo agredían en la primaria, aunque solo le
    llevo 11
    meses, para él era como su mamá.</p>
  <p>A mi otro hermano le gano por tres años, le sigue el hermano hombre más pequeño que ahora tiene 21 años, con él
    siempre tenía mucho cuidado porque tiene una enfermedad que se conoce como huesos de cristal.</p>
  <p>Mi hermano ha sido muy apegado a mí, aunque tenemos nuestras diferencias, pero es uno de los que me sigue más.
    Ya
    después nació mi hermana, la más chica.</p>
  <p>Cuando era pequeña yo jugaba como los niños, jugaba con mis hermanos a las canicas, a los tazos. En ese tiempo
    estaban los Power Ranger, jugaba a eso con ellos. Tuve una infancia muy feliz.</p>
  <p>Con mi hermano &quot;el Huevo&quot; éramos como dos amigos, como a él no le gustaba pelear yo era la que lo
    defendía
    y a mi mamá siempre la mandaban llamar porque yo peleaba. A mi hermano me lo mataron el día 20 de diciembre de
    2019.</p>
  <p>Un día antes, el 19 de diciembre lo vi, platicamos con él. Ese día acudimos con Fernando al hospital pediátrico
    de
    León para llevarles juguetes a los niños que estaban hospitalizados. En la mañana del 20 de diciembre le mandé
    un
    audio porque yo me sentía rara, ya como a eso de las 9 de la noche me entró una llamada de mi mamá, comencé a
    escuchar
    un montón de sirenas. Mi mamá lloraba y me decía: &#39;me lo mataron, me lo mataron&#39;.</p>
  <p>Precisamente cuando mi hermano estaba cumpliendo siete meses de haber muerto se llevaron a Fernando. En solo
    siete
    meses me quitaron a dos de las personas que más quiero.</p>
  <img src="assets/matilde/2.jpg" class="img-fluid my-5" alt="">
  <p>Tengo 28 años y dos hijos: un niño que va a cumplir 14 años de mi anterior pareja y la niña de seis años, hija
    de
    Fer.</p>
  <p>Fui mamá por primera vez a los 15 años, a mi fiesta yo iba embarazada, pero mis papás no sabían en ese momento.
    Es
    algo curioso porque mi mamá y yo estábamos embarazadas al mismo tiempo, ella de mi hermana la más chica y yo de
    mi
    primer hijo.</p>
  <p>Cuando supe que estaba embarazada sentí mucho miedo, cuando le dije al papá de mi hijo lo que pasaba llegó a
    pedirme
    que lo abortara porque él no lo quería, pero yo luché porque mi hijo naciera. Me armé de valor.</p>
  <p>Tuve que salirme de la escuela, yo ya iba en la prepa. Entré a trabajar para poder comprarle a mi niño una
    cobija,
    ropa, pañales para cuando naciera y no estar dependiendo del papá.</p>
  <p>Trabajé en una empresa de limpieza, me ponían a barrer calles, yo fui a trabajar hasta un día antes de que
    naciera mi
    bebé, hice el esfuerzo porque no quería que a mi hijo le faltara nada.</p>
  <p>No fue muy díficil ser mamá en cuestión de cuidarlo porque yo cuidé a mis hermanos, lo díficil fue la cuestión
    económica. Los gastos de leche, pañales, de vestimenta siempre los tenía que pagar yo. A la fecha, me hago cargo
    de
    todo.</p>
  <p>Durante años viví en un infierno con el papá de mi hijo, desde que estaba embarazada del niño sufría
    agresiones.
    Siempre me golpeaba, me humillaba, me hacía sentir como una basura, decía que nadie se iba a fijar en mí, que
    nadie me
    iba a querer. Son palabras que ya pasaron, pero no se me olvidan.</p>
  <p>Luego conocí a Fernando y llegué a un lugar donde todo era felicidad, donde no escuchas un grito, no te dicen
    una
    mala palabra, no te levantan la mano. Yo creía que ya había salido de lo duro, que por fin podía ser feliz.</p>
  <p>Cuando empezamos a salir, Fer me decía que iban a pensar que yo era su hija por la diferencia de edades, cuando
    desapareció había cumplido 41 años, pero a mí nunca me importó lo que la gente pensara, porque nos dimos la
    tranquilidad emocional que necesitábamos.</p>
  <p>Mi esposo era jefe de circulación de un periódico, vivíamos en Salamanca.</p>
  <p>Nos levantábamos con él a las 4 de la mañana, nos íbamos a trabajar. Llegábamos al lugar donde recogíamos el
    periódico, íbamos por las voceadoras para dejarlas en su punto.</p>
  <p>Teníamos un pequeño negocio, vendíamos en los tianguis ropa y zapatos, nos íbamos todos los lunes para amanecer
    martes en la moto de Salamanca a Moroleón.</p>
  <p>Nos aventurábamos a la nada, era irnos de noche. El regreso a la casa era pararnos en cualquier montaña,
    veíamos el
    paisaje, para mí eso era bonito porque no necesitábamos dinero para demostrarnos el cariño. Todo era como una
    aventura.</p>
  <p>Vivimos juntos desde hace tres años, en nuestro pasado sufrimos mucho, entonces seguirle sus locuras era
    bonito. Me
    decía que disfrutáramos lo que teníamos.</p>
  <p>Nos subíamos a los juegos en la feria como si fuéramos dos niños.</p>
  <p>Aunque estuviéramos en la casa me mandaba mensajes, videos, algunos para hacerme reír, y cuando no estaba en la
    casa
    era para recordarme que me amaba.</p>
  <p>El 30 de abril me dijo que cambiara a la niña porque le había prometido que la llevaríamos al lago y ese día
    íbamos a
    ir. Yo no sé si presentía que le iba a pasar algo.</p>
  <p>A veces estábamos en la casa y me decía que fuéramos al jardín, al centro. Íbamos y nos sentábamos en una
    banca, nos
    comprábamos un elote, nos poníamos a platicar y parecía que éramos novios. Eran cosas que no se compraban con
    dinero,
    pero son las que más recuerdo.</p>
  <p>Me acuerdo bien de que para su cumpleaños el 9 de mayo no teníamos dinero, pero en nuestra casa nunca faltaba
    la
    harina para hot cakes, la leche, porque él era muy de comer hot cakes, de comer pan.</p>
  <p>Mi niña y yo decidimos hacerle muchos hot cakes y se lo decoramos como si fuera un pastel, mi niña le cantó las
    mañanitas. Son cosas que él no había vivido, se ponía a llorar de felicidad como si fuera un niño.</p>
  <p>Todos esos sueños, las promesas, los planes, la vida feliz, la tranquilidad, todo terminó cuando unos hombres
    se
    llevaron a mi esposo frente a mí y a mi hija.</p>
  <img src="assets/matilde/3.jpg" class="img-fluid my-5">
  <p>El viernes 13 de julio de 2020 mi esposo regresaba de León a Salamanca, en la carretera, a la altura de Silao,
    policías estatales lo detuvieron. Primero dijeron que era por evasión, luego que la moto en la que él iba era
    robada y
    después que llevaba droga. Lo enviaron al Pentágono, cuando lo presentaron ante el Ministerio Público iba
    golpeado.</p>
  <p>Me habló, yo fui a Silao donde vi cuando cinco policías lo llevaban. Al no poder comprobar ninguno de los
    delitos, el
    Ministerio Público dijo que tenían que liberarlo. Me dijeron que el domingo iba a salir del Pentágono.</p>
  <p>A las 2:50 de la tarde él salió, lo esperábamos mi hija y yo afuera, le puso las agujetas a sus zapatos y ya
    nos
    íbamos en el camión. Una cuadra adelante mi hija vio un señor que vendía helados y nos bajamos a comprar, fue en
    ese
    momento que llegó el Jetta gris, hombres armados se bajaron y subieron a la fuerza a mi esposo.</p>
  <p>Ellos dijeron que eran ministeriales. Mi esposo les dijo que no había hecho nada, pero lo obligaron a subirse
    al
    vehículo. Mi esposo lo único que me gritaba era &#39;cuida mucho a la niña, cuida mucho a la niña&#39;.</p>
  <p>Detrás del vehículo en que se llevaron a mi esposo iba una patrulla de la policía de Silao, yo gritaba que me
    ayudaran, que habían secuestrado a mi esposo, pero no me hicieron caso. Me vieron y siguieron el camino como si
    fueran
    custodiando el Jetta. 20 minutos más tarde regresó la misma unidad.</p>
  <p>Una policía se bajó y solo me dijo: &#39;mira, tú ya sabes de aquí qué sigue. Toma a tu niña y vete a tu casa&#39;.
    Todavía me sigo preguntando a qué se refería.</p>
  <p>Yo nunca vi que se llevara a cabo un plan de búsqueda.</p>
  <p>Se supone que cuando a una persona la detienen, ni policía municipal ni estatal tiene el derecho de brindar
    información o fotografías a medios locales, sea el medio que sea. No fue así, hicieron llegar a un medio de
    Silao
    información y una foto de mi esposo que le tomaron afuera del Pentágono.</p>
  <p>Quiero saber quién es la persona responsable de la fuga de información, porque gracias a ellos se destrozó una
    familia con la pérdida tan grande de la cabeza de la familia.</p>
<p class="text-center">✽✽✽</p>
<p>Desde el día que se llevaron a Fernando yo iba casi diario a la agencia del Ministerio Público, me decían que
    lo
    estaban buscando. Yo creí en lo que ellos me dijeron.</p>
  <p>Me decían: &#39;no se preocupe, doñita, si nosotros sabemos algo, nosotros le hablamos&#39;. El Ministerio
    Público me
    decía &#39;por ahí debe andar su esposo, al rato aparece&#39;.</p>
  <p>Dos días después de que se lo llevaron yo fui a Silao a hablar con una agente de Investigación Criminal (de la
    Fiscalía) para hacerle saber que el celular de mi esposo estaba prendido, pedí que se rastreara la ubicación.
    Ella me
    dio un número de celular para mantener contacto, leía mis mensajes, pero nunca los respondía. Yo dije: &#39;no
    me van
    a ayudar&#39;.</p>
  <p>Opté por buscar a mi esposo por mis propios medios. Me uní a un colectivo de búsqueda, era ahí donde me
    apoyaban.</p>
  <p>Durante 25 días yo destiné la vida entera y las emociones, tenía que sacar fuerzas para buscarlo. A veces
    acompañada
    de mi hermano, siempre con mis hijos, recorrí los hospitales de Salamanca, Irapuato, Silao y León. Preguntaba
    por
    hombres heridos que no estaban identificados para que me permitieran pasar a los cuartos a verlos, cuando
    encontraba
    tres o cuatro hombres sin identificar, el personal médico me dejaba pasar a ver si encontraba a Fernando.</p>
  <p>Recorrí los Ceresos de Valle de Santiago, de Irapuato, de León y de Celaya, también pasé por los separos
    municipales.
    A cada lugar entraba con esperanza y salía destrozada.</p>
  <p>Acudía a Semefo (Servicio Médico Forense) en la ciudad de León, acudí a Semefo en Irapuato, donde supuestamente
    se me
    mostraron fotografías a nivel estado, donde incluían cuerpos que estaban como no identificados.</p>
  <p>En la Fiscalía me trajeron casi un mes con puras mentiras. Mi esposo estaba en Semefo en Celaya desde el 20 de
    julio
    y yo no lo sabía.</p>
  <p>Lo encontré el 13 de agosto sin la ayuda del Ministerio Público, de ninguna autoridad. Mi familia me apoyó,
    también
    mis compañeras del colectivo al que me uní cuando me di cuenta que en la Fiscalía no iban a hacer nada.</p>
  <p>Al principio se nos había informado que a mi esposo lo habían encontrado en un canal de aguas negras, este
    cuerpo
    había fallecido por un disparo de arma de fuego en la cabeza.</p>
  <p>Un día después, el viernes 14 de agosto nos hacen el certificado de defunción y me percato que mi esposo no
    había
    muerto por un disparo de arma de fuego, a mi esposo lo decapitaron.</p>
  <p>Nos entregaron el cuerpo a las 2 de la tarde y hasta entonces nos piden hacer la identificación forense.</p>
  <p>Semefo ni siquiera se tomó el tiempo de poner la cabeza en su lugar, a mí me lo entregaron así decapitado, en
    una
    bolsa, la cual contenía demasiado líquido y sangre. No se tomaron el tiempo de limpiarlo, cosa que a mi me
    parece
    humillante porque mi esposo no era un animal. ¡Vaya! ni un animal merece eso.</p>
  <p>En el certificado pusieron que a mi esposo lo encontraron en la comunidad de La Noria en Apaseo el Grande.</p>
  <p>Mi esposo tampoco fue localizado en Apaseo el Grande, fue encontrado entre Salvatierra y Tarimoro.</p>
  <p>Lo único que quiero es que se haga justicia porque mi esposo no merecía morir, mi esposo no tenía problemas con
    nadie. Que se investigue lo que se tenga que investigar, que se haga lo que se tenga que hacer, pero que hagan
    justicia, que me digan qué fue lo que pasó.</p>
  <img src="assets/matilde/4.jpg" class="img-fluid my-5" alt="">
  <p>Me queda el sentimiento de culpa de que yo pude haber hecho más, es algo que no me deja en paz, tengo que
    estarlo
    cargando diario. Yo sé que a lo mejor no fue mi culpa, imposible que lo pudiera defender, pero si yo me hubiera
    aferrado más a los policías, si yo les hubiera pedido más fuerte que lo buscaran, a lo mejor lo hubiera
    encontrado
    vivo.</p>
  <p>Después de todo me uní a las búsquedas de campo con la Comisión Estatal de Búsqueda y colectivos, fui a la que
    se
    hizo en la comunidad Malpaís en Valle de Santiago, también he ido a las que se han hecho en Salvatierra.</p>
  <p>En el colectivo al que pertenezco, Buscadoras Guanajuato, y en otros colectivos tengo compañeras de la tercera
    edad,
    están enfermas y por su edad ya no les es posible ir a las búsquedas.</p>
  <p>Yo quiero ayudarlas, quiero regresarles un poquito de lo que ellas me ayudaron a mí. Aunque ellas no me
    acompañaban
    físicamente a Semefo o a los hospitales, siempre estuvieron al pendiente de todo lo que pasó.</p>
  <p>Al principio mi familia me decía &#39;ya para qué buscas si ya lo encontraste&#39;. Sí, pero tal vez hay muchas
    personas que no tienen la manera de ir, no tienen la facilidad porque ir a una búsqueda es ir entre piedras,
    arañas,
    cortando hierba, abrir una vereda para llegar a donde hay rastros de cuerpos o ropas.</p>
  <p>Cuando veo que el forense se lleva los cuerpos que sacamos, lo único que digo es &#39;ya te encontramos, ya vas
    a
    regresar con tu familia&#39;, aunque no los conozca.</p>
  <p>A mi hija su papá le dejó una tarea y cada que alguien le pregunta:</p>
  <p>-¿Para qué quería tu papá que estudiaras?</p>
  <p>Ella responde:</p>
  <p>-Para ser fuerte y no depender de ningún hombre.</p>
  <p>Es algo que ella tiene en su cabecita.</p>
  <p>Ella ha recorrido conmigo todo este camino. Mi hija, a pesar de que solo tiene seis años es bien valiente,
    siempre ha
    sido bien valiente.</p>
  <p>Hace una semana me decía que quiere ser una buscadora como yo, quiere buscar personas, porque no quiere que las
    demás
    niñas sufran como ella, que no estén tristes.</p>
  <p>La gente tiene la tonta idea de decir que por algo se los llevaron, que andaban metidos en algo malo, pero
    muchas de
    las veces no es así.</p>
  <p>Tengo bien clara la idea de que ningún ser humano por malo que haya sido merece que los desaparezcan, ninguno
    merece
    morir de la manera en la que Fernando murió.</p>
  <p>Conozco muchos casos de buscadoras, algunas con maestría, muchas niñas, hombres de familia que no merecen vivir
    lo
    que están viviendo.</p>
  <img src="assets/matilde/5.jpg" class="img-fluid my-5" alt="">
  <p>Aquí en la casa le tengo su altar con la lona que le mandé hacer en un aniversario, siempre le tengo flores, su
    vela,
    postits con mensajes, porque yo sé que él me escucha, que él sigue aquí, que sigue conmigo.</p>
  <p>Al principio yo tenía mi mente ocupada en hacer trámites, ir aquí, ir allá, ahorita mi tiempo como que se
    empieza a
    desocupar y es cuando veo las cosas. Ir a un lugar y recordarlo es difícil porque es tratar de aceptar que ya no
    va a
    estar.</p>
  <p>Mi vida ahora es amanecer, estar en la casa, anochecer y esperar a que mis hijos se duerman para poder llorar. Es
    una
    situación que no le deseo a nadie.</p>
  <p>Perdí mi negocio, lo que hago es que saco ropa que ya no me queda a mí, que ya no les queda a mis hijos y me
    voy a
    vender a las plazas.</p>
  <p>Aparté su lápida, como pude fui juntando el dinero. Se la puse sencilla, a mis posibilidades. Solo esperaba el
    día
    para poder ir a ponerla, apenas se la pudimos colocar.</p>
  <p>A mí no me gustaba hablar de la muerte, pero él siempre me decía que cuando ya no estuviera lo recordara como
    él era
    de alegre, que cuando alguien me pidiera de comer yo no le negara un taco a nadie porque era de la manera en que
    yo
    iba a saber que él iba a estar cerca de mí.</p>
  <p>Hay un muchacho de la colonia que tiene problemas familiares, seguido lo corren de su casa, él siempre me decía
    &#39;júrame
    que el día que no esté y mientras tú puedas a él nunca le va a faltar un taco&#39;.</p>
  <p>Te queda el recuerdo, pero es difícil porque sabes que no van a volver los paseos con él, los detalles, las
    risas.</p>
  <p>Teníamos la promesa de ir a la playa todos, por la falta de dinero nunca pudimos cumplirla. Ahora que se pueda
    quiero
    ir a hacer algo así como una ceremonia.</p>
  <p>Él se quería morir en la costa, que sus cenizas las arrojarámos al mar. Me habría gustado hacerlo,
    desgraciadamente
    el Ministerio Público no me permitió cremarlo, pero me comentaba una psicóloga que si tengo la oportunidad de ir
    a la
    playa lo haga con una foto, quemar una foto de él, hacer una carta para él, quemarla y arrojar todas las cenizas
    al
    mar.</p>
  <p>Lo haré.</p>
  <p class="mt-5"><em>*Matilde es el nombre ficticio que se dio a la buscadora por seguridad</em></p>
  <div class="intro-container black d-flex justify-content-center my-5">
    <div class="col-md-3 align-self-center">
      <img src="assets/matilde/silueta.jpg" alt="" class="img-fluid">
    </div>
  </div>

</div>
