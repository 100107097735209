<div class="row justify-content-center">
  <a target="_blank"
     [href]="fb"
     class="col-md-1 col-12 social-link-custom text-center">
    <mdb-icon fab icon="facebook-f"></mdb-icon>
  </a>
  <a target="_blank"
     [href]="tw"
     class="col-md-1 col-12 social-link-custom text-center">
    <mdb-icon fab icon="twitter"></mdb-icon>
  </a>
  <a target="_blank"
     [href]="wh"
     class="col-md-1 col-12 social-link-custom text-center">
    <mdb-icon fab icon="whatsapp"></mdb-icon>
  </a>
</div>


<div class="spacer"></div>
