<div class="container text-center">
  <a href="https://poplab.mx" target="_blank">
    <div class=" black d-flex justify-content-center">
      <div class="col-md-5 align-self-center">
        <img src="assets/nav/credito.jpg" alt="" class="img-fluid">
      </div>
    </div>
    <div class=" black d-flex justify-content-center">
      <div class="col-md-3 align-self-center">
        <img src="assets/nav/poplab.jpg" alt="" class="img-fluid">
      </div>
    </div>
  </a>

  <h6 class="text-center text-white my-3">24 noviembre, 2020</h6>
  <hr class="white">

  <div class="row">
    <div class="col-md-6">
      <p class="text-center" style="line-height: 2rem">
        <span class="h6 border-bottom">Coordinación y edición:</span> <br>
        Verónica Espinosa <br>
        <span class="h6 border-bottom">Coedición:</span> <br>
        Kennia Velázquez <br>
        <span class="h6 border-bottom">Investigación:</span> <br>
        Carmen Pizano, <br>
        Alonso Merino Lubetzky, <br>
        Martha Silva, <br>
        Kennia Velázquez <br>
        <span class="h6 border-bottom">Asesor:</span> <br>
        Marcos Vizcarra
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-center" style="line-height: 2rem">

        <span class="h6 border-bottom">Dirección artística y arte:</span> <br>
        Pinche Einnar <br>
        <span class="h6 border-bottom">Diseño y desarrollo web:</span> <br>
        Nicolás Aranda <br>
        Miguel Cabrera <br>
        <span class="h6 border-bottom">Producción audiovisual:</span> <br>
        Juan José Plascencia <br>
        <span class="h6 border-bottom">Redes sociales:</span> <br>
        Orlando Humérez <br>
        Brenda Orozco
      </p>
    </div>
  </div>
  <div style="height: 50px"></div>
</div>
