<div class="intro-container black d-flex justify-content-center">
  <div class="col align-self-center">
    <img src="assets/portadas/elia-d.jpg" alt="" class="img-fluid d-none d-md-block">
    <img src="assets/portadas/elia-m.jpg" alt="" class="img-fluid d-block d-md-none">
  </div>
</div>
<div class="container">
  <app-sharers
    fb="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuscadoras.poplab.mx%2Felia"
    tw="https://twitter.com/intent/tweet?text=Cuando%20ellas%20buscan%3A%20elia&url=https%3A%2F%2Fbuscadoras.poplab.mx%2Felia&via=poplabmx"
    wh="https://wa.me/?text=Cuando%20ellas%20buscan%3A%20elia%20https%3A%2F%2Fbuscadoras.poplab.mx%2Felia%20"
  ></app-sharers>

  <h5>
    <i>Desde que estoy en el colectivo siento que hago algo por encontrarlo; el gobierno no hace nada con el tema
      de los desaparecidos, ni escucha, ni ayuda, ni da protección. Como si estuvieras hablando a la pared.
    </i>
  </h5>

  <p>Por:<strong> Martha Silva</strong></p>

  <img src="assets/elia/1.jpg" class="img-fluid my-5" alt="">

  <p>Yo no conozco lo que es la felicidad. He tenido destellos, chispazos. Cuando nacieron mis hijos, cuando estoy con
    mis
    nietos, cuando íbamos a comer todos juntos y cantábamos. Estábamos tranquilos. La vida me puso pruebas y pude
    salir
    adelante, por ellos. Luego, ellos crecieron y se volvieron mi apoyo. Un día mi hijo no llegó a dormir y se nos
    acabó
    la paz. Ahora ya no tengo otra motivación más que encontrar a Alfredo. Sigo viva por mis otros hijos, pero nunca
    dejaré de buscarlo a él, mi motor, mi hijo mayor.</p>
  <p>Vengo de una familia de mujeres comerciantes. Mi abuela fue comerciante, mi madre igual. Yo también. Nací en
    Nuevo
    Urecho, Michoacán. A los 10 años me vine a vivir a Irapuato. Aquí me casé a los 16 años y tuve a mis hijos;
    Alfredo
    fue el mayor, también es cerrajero, una tradición que heredó de su abuelo y de su padre.</p>
  <p>Por eso era muy conocido por los licenciados, porque trabajaba con los actuarios en los embargos, pero en
    ocasiones
    también compraba y vendía coches. El día que desapareció andaba vendiendo uno.</p>
  <p>Alfredo desapareció el 21 de octubre de 2018. Fue una amiga suya quien lo invitó a un palenque, en la comunidad
    de
    San José de Guanajuato, en Celaya. Con Alfredo se perdieron otras cuatro personas: Nancy, Monserrat, Maritza y
    Moisés,
    ellos dos eran un matrimonio y las dos primeras, sus empleadas.</p>
  <p>Cuando mi muchacho no volvió a la casa con mi nuera, fuimos al Ministerio Público a presentar la denuncia, pero
    ahí
    no la quisieron recibir sino hasta pasados ocho días, que hasta que estuvieran todos juntos, dijeron. En mi
    desesperación, a tres días de no tener noticias suyas, me fui a recorrer los ranchos cercanos, a preguntarle a la
    gente.</p>
  <p>Hasta después supe del peligro en el que puse a los dos familiares que me acompañaron: en la zona se asienta uno
    de
    los grupos criminales que se pelean el territorio por delitos como el robo de combustible, las extorsiones, los
    homicidios y muchos más. Ese día, por andar preguntando, se nos acercaron personas en actitud hostil.</p>
  <p>En nuestra vida no había entrado la violencia, la nuestra era una vida tranquila. Mi hija y él eran muy unidos,
    se
    acompañaron siempre aunque ella era muy seria y mi hijo muy amiguero. Por eso ahora ella me acompaña a todas
    partes,
    pero parece que ella es la más acabada de las dos. Yo soy fuerte, me considero fuerte.</p>
  <p>Pero lo que yo estoy pasando ahora es más fuerte que cualquier cosa del mundo, para mí la desaparición de mi hijo
    es
    lo más fuerte. Hubiera preferido que me dejaran mil hombres, a perder a mi hijo. La verdad, no me dolería de una
    persona que no es nada de mí, que no nació de mí. No me importa que yo tuviera que mantener a mis hijos. Ahora
    solo me
    interesa encontrar a mi hijo.</p>
  <img src="assets/elia/2.jpg" class="img-fluid my-5" alt="">
  <p>Me separé de mi esposo, el papá de Alfredo, a los 10 años de casada, cuando de plano no aguanté las golpizas que
    me
    daba. Lo denuncié muchas veces. La última vez casi me mata, no solo me pegaba, me rompió el tabique de la nariz y
    me
    hizo otras fracturas, en las piernas pero sobre todo en la cara, para desfigurarme, quería que no me quedara con
    nadie, así, tal cual, me lo dijo. Sí lo metían a la cárcel pero lo dejaban salir al otro día. Los policías me
    dijeron
    esa vez: «Si quieres vivir, déjalo». Desde ese momento vi que aquí no hay ningún tipo de justicia.</p>
  <p>Como me quedé sin nada, solo con mis dos primeros hijos, que estaban chiquitos, volví a empezar y me fui de
    ilegal a
    Estados Unidos. Yo crucé la frontera embarazada. Un tío me iba a alojar en Los Ángeles. En el camino, mi hija se
    me
    perdió ocho días en un cerro, hasta que «el coyote» nos avisó que estaba con él, que estaba bien. Gracias a Dios
    que
    me regresó esa hija, pero ahora ya se me perdió este otro.</p>
  <p>Duré tres años trabajando en casas. Mi tercer hijo nació allá, como metí a los otros dos a la escuela, ellos
    aprendieron algo de inglés, yo no, pero sí aprendí a manejar y pude sacar un carro. Allá te dan apoyos para los
    bebés,
    a los niños les dan de comer en la escuelas. Allá cuidan a las personas, aquí no. Pero yo quería estar en México,
    aquí
    vivía mi familia, aquí estaban construyendo mi casa, para eso mandaba dinero. Incluso tenía una maleta lista para
    el
    día en que decidiera regresarme, tenía ropa, perfumes, plumas, recuerdo que éstas se secaron porque duraron mucho
    tiempo guardadas.</p>
  <p>En mala hora lo hice, en mala hora me regresé. Desde que estoy aquí, ha sido estar trabaje y trabaje, ni un día
    de
    descanso, para sacarlos adelante. La vida aquí es más difícil, aunque mis hijos no andaban bien vestidos, no
    andaban
    descalzos. Me puse a vender zapatos, aunque también puedo cocinar y sacar pedidos.</p>
  <p>A mi hijo el mediano, que como nació en EUA sí puede cruzar sin problemas, le pedí que mejor ni venga, no quiero
    que
    algo le pase.</p>
  <p>Mi hijo menor recién cumplió 18 años, él hasta cayó en depresión, se salió de la escuela y entró a trabajar. A
    veces
    se queda todo el día solo, come solo, ya es diferente. Yo pensé que él, el chiquito, era quien estaba en más
    riesgo,
    por lo mismo de la edad. No Alfredo, porque él ya es un hombre hecho y derecho, trabajador, generoso.</p>
  <p>Alfredo era como el padre de mis otros hijos: fue el que les enseñó a manejar. Incluso es padrino de uno de los
    niños
    de mi hija y como Laura también prefirió separarse, convivían mucho con él, lo deben extrañar mucho.</p>
  <p>Cuando mi hijo desapareció, duramos muchos días encerrados, llorando, todos juntos, esperando a que regresara.
    Tener
    alguna noticia suya. Pero ni el coche que traía fue encontrado. O eso me dicen. He ido a preguntar a los lotes de
    los
    coches recuperados por la Fiscalía y ni siquiera me dejan entrar a revisar.</p>
  <p>Ahora la gente me dice «Ya suéltalo, ya déjalo, ya no lo busques, te vayan a hacer algo malo a ti». Les digo que
    a mí
    no me importa, es mi hijo y nunca lo voy a abandonar hasta que Dios me recoja.</p>
  <p>Me trataba con una psicóloga. Me la pusieron las autoridades después de que empecé a ir al Semefo a reconocer
    cadáveres. Pero un día me habló por teléfono, no alcancé a contestar y ya no me ha vuelto a marcar.</p>
  <p>Con la desesperación de encontrar a tu hijo, a veces hablas a lo puro menso. Un día, en un reportaje donde me
    entrevistaron para la televisión, pedí que los que se lo llevaron digan dónde tienen a los desaparecidos, dónde
    los
    enterraron. Las autoridades dicen que no los pueden obligar a hablar, pero yo digo que sí, que los obliguen.</p>
  <p>Por eso, una señora que me conoce, me dijo «tú para qué andas haciendo eso, de salir en las noticias, con lo que
    dices te estás comprometiendo, te van a matar». Yo le contesté, «por eso, es lo que se tiene qué hacer,
    preguntarles
    dónde los enterraron».</p>
  <p>Alfredo cumplió 34 años el pasado 17 de abril.</p>
  <img src="assets/elia/3.jpg" class="img-fluid my-5" alt="">
  <p>Es una desilusión de que el gobierno no hace nada con el tema de los desaparecidos, no te escucha, no te ayuda ni
    te
    da protección, nada. Como si le estuvieras pidiendo ayuda a la pared. Con esa desesperación fui a pedirle ayuda al
    alcalde de Irapuato, Ricardo Ortiz, él movió la cabeza y dijo que estaba muy difícil. Aún así fui con mucha gente
    a
    pedirle ayuda, a los tres días que Alfredo desapareció fui a las noticias, creyendo que me iban a ayudar y nada.
    No
    pasó nada.</p>
  <p>En el MP no me quisieron tomar la denuncia de la desaparición de Alfredo cuando fui, que hasta que estuvieran
    todos,
    luego me citaron para una junta, donde nos dijeron cómo habían entrado los carros, puro bla-bla-bla. Yo casi
    diario
    iba a preguntar de algún avance y todo iba igual, igual y hasta la fecha. Lo de las llamadas de celular, hasta
    como al
    medio año lo investigaron. ¿Ya para qué? Ya mi hijo solo Dios sabe si está aquí.</p>
  <p>Es lo malo de ser pobre, si fuera uno rico, rápido encontraban a nuestro ser querido y paga uno el rescate y ya.
    Igual, si fuera funcionario. Supimos de un caso, que sí devolvieron a la persona. Fueron las Fuerzas del Estado a
    sacarlo de una casa, así le hubieran hecho con mi hijo y con los que desaparecieron con él, pero no. Nada
    hicieron.</p>
  <p>Tan solo el pasado sábado nos fuimos las dos, mi hija y yo, a Celaya. El licenciado nos citó a las 12 pero él
    llegó
    bien tarde y casi seis horas duramos ahí, enseñándonos las cosas que ha buscado y que según han encontrado. La
    última
    vez que lo vimos fue hace tres meses.</p>
  <p>Regresamos a la casa en la tarde, con hambre. Gastamos en gasolina, tiempo... Y como tuvimos que dejar a los
    niños
    encargados en una casa porque por la pandemia ya no podemos llevarlos con nosotros, uno se cayó y se
    descalabró...</p>
  <p>Es una cosa horrible... Yo ando con miedo, tengo tanto miedo de ir a Celaya, pero tenemos que ir a ver cómo va el
    caso
    porque si no, ellos no mueven un dedo. La verdad es que me desilusionan, ¿entonces qué están haciendo, qué
    hacen?</p>
  <p>Si lo del robo del petróleo, del huachicol, es lo de menos, no tiene tanta importancia como las personas, con las
    miles de desapariciones que se han visto en los últimos años, eso es lo que más duele. Se los llevan a ellos pero
    a
    nosotros, las familias, nos matan en vida. Nosotros éramos muy unidos. Ahora ya no nos juntamos, ya no hacemos
    fiestas, no festejamos nada, anda cada quien por su lado. Las búsquedas, las juntas del colectivo, nos quitan
    mucho
    tiempo. Con su ausencia, se acabó todo para nosotros.</p>
  <p>Los fines de semana nos organizábamos e íbamos a Guanajuato, al Callejón del beso, a comer a la Presa de la Olla
    en
    lancha. Alfredo compraba pollos rostizados y comíamos muy contentos. Él era muy generoso, siempre pagaba. También
    hacía viajes: alquilaba un camión sin ganancia para él, para llevar a la familia de mi nuera y a nosotros a
    visitar la
    playa, fuimos como tres veces a Ixtapa. Recuerdo que muy temprano se iba a desayunar al mercado de Zihuatanejo y
    que
    me daba miedo cuando él se aventaba clavados. Pero siempre salía.</p>
  <p>A mí nunca me ha gustado sacarme fotos pero sí me tomaban. Tengo una foto que nos tomamos la última vez que
    fuimos a
    la playa. Y sí me gustó mucho porque salimos los dos.</p>
  <p>También hacíamos viajes más cerca: Valle de Santiago, Yuriria, ahí me llevó en mi último cumpleaños. O nos
    juntábamos
    para comer o cenar, él con su familia, mi hija con la suya y mi hijo el menor y yo. A Alfredo le encanta la sopa
    de
    mariscos, yo la hago bien picosa, y a veces, sin ningún motivo, nos juntábamos los jueves, los viernes, en mi
    casa.
    Los domingos no, porque yo me iba a vender al tianguis. Ahora con lo del coronavirus, ya no dejaron vender ahí,
    tengo
    que andar vendiendo en las colonias, con mis conocidas, mis amistades.</p>
  <p>El gobierno no nos da seguridad, ni salud. Puro bla-bla-bla. Ellos están bien contentos en su casa, comiendo,
    divirtiéndose, haciendo fiestas, a ellos qué les importa el sufrimiento que tiene el pueblo.</p>
  <p>A los que se llevaron a mi hijo, yo quisiera decirles que si ya algo les debía con la vida les pagó, que por
    favor
    nos digan dónde está. Y no solo de Alfredo, sino de todos los desaparecidos: que no los entierren o los quemen,
    necesitamos saber dónde están.</p>
  <p>Yo, en mi desesperación he recurrido hasta a adivinos. Les pregunto dónde está Alfredo y ellos me dicen que está
    vivo. Pero cuando pregunto detalles, me empiezan a pedir más dinero de los 500 pesos de la consulta, para decirme
    dónde está. Y entiendo que solo quieren sacarme dinero y dejo eso por la paz. Pero en mi corazón sigue el hueco
    por mi
    hijo.</p>
  <p>No dejo de pensar en él, día y noche pienso en mi Alfredo. Le platico, le hablo en las noches, cuando estoy sola
    en
    la casa. Le pregunto «Hijo, ¿dónde estás?, dime dónde estás». También le pido perdón por quizá no darle el amor
    que
    necesitaba, fui madre recia, dura. Pero traté de conducirlos a todos por el buen camino, seré pobre pero honrada.
    Cuando me separé del papá de mi hijo el más chico, de nuevo me quedé sin un quinto. Es que estaba repitiendo la
    historia: de nuevo violencia. Física y psicológica, pero sobre todo de esta última. No me veía con fuerza para
    volver
    a quedar sola, por eso aguanté muchas cosas. Pero tuve que volver a salir adelante, por mis hijos.</p>
  <p>Ahora, ya sin el apoyo económico de Alfredo, tengo que salir a ganarme la vida. Salgo a vender mis gelatinas,
    ropa
    usada, adornos de mi casa. Pero hay días en ni vendo nada.</p>
  <p>Alfredo tiene una hija, ya es adolescente, la veo muy triste, muy sola. Ya casi no me visita porque me la paso en
    la
    calle tratando de vender mis cosas. No he recibido ningún apoyo económico, aunque dos veces en dos años nos dieron
    una
    despensa que nos repartimos entre mi nuera, mi hija y yo. Era del gobierno de Guanajuato, porque el de aquí, de
    Irapuato, no me ha dado ni un chicle.</p>
  <img src="assets/elia/4.jpg" class="img-fluid my-5" alt="">
  <p>La pista más importante del caso de mi hijo es su credencial de elector, encontrada en un predio bardeado en
    Santa
    Rosa de Lima, con una pared balaceada. La encontraron tirada en febrero de 2019, cuando el gobierno entró a esa
    comunidad con el dichoso operativo «Golpe de timón» pero a mí me dijeron del hallazgo hasta cuatro meses después,
    en
    mayo. Me preguntaron si reconocía la credencial, ¿cómo no la voy a reconocer? Era mi hijo. La credencial está
    intacta,
    ni maltratada ni decolorada y de ahí no pudieron sacar ni siquiera una huella. Las investigaciones revelaron que
    en
    este lugar se realizaron las últimas llamadas pero ahí no han escarbado. No sé si mi hijo está ahí.</p>
  <p>El gobierno no da razón de lo que encuentra, porque no quiere decir cuánta gente han matado en esta guerra de
    cárteles, no le conviene.</p>
  <p>Es que yo no conocía todo esto, nunca pensé que fuera a pasarme.</p>
  <p>Supe del colectivo «A tu encuentro» porque nos invitaron a una junta, en una iglesia. Fuimos mi hija y yo. Somos
    como
    50 o 60 personas ahí en el grupo. Por la pandemia hicimos una pausa, pero ya nos estamos juntando otra vez. Ayuda
    saber que estamos luchando y que estamos haciendo algo por ellos, para encontrarlos.</p>
  <p>Ahora me doy cuenta de la magnitud y cuánta gente se desaparece o tiene necesidad. Aunque muchas veces fui con
    mis
    hijos a repartir comida afuera de los hospitales, a los necesitados, nunca pensé que yo misma me iba a ver en la
    necesidad de agradecer un taco.</p>
  <p>Fui de las mujeres que participaron en el plantón del Teatro Juárez. Esperábamos que Diego Sinhue escuchara
    nuestra
    petición, de que no pusiera a ese señor, Héctor Díaz Ezquerra, como comisionado estatal de Búsqueda, porque no
    sabe
    nada de eso. Yo le mando bendiciones, pero ojalá le pusiera acción a hacer las cosas, porque al final de cuentas
    el
    plantón no tuvo ningún resultado.</p>
  <p>Aunque fue bonito cuando la gente de Guanajuato capital nos llevó cobijas y comida.</p>
  <p>Desde que estoy ahí en el colectivo, siento que estoy haciendo algo por encontrar a Alfredo, por eso he ido a
    todas
    las juntas, a los plantones, he salido a búsquedas, he andado en todo. La que más ha buscado a su hijo, soy yo y
    mi
    hija Laura. Y ella es de las que van a sembrar árboles con el colectivo.</p>
  <p>Pero es una cosa horrible cuando al grupo llega una persona que se le acaba de perder su hijo, su hija. Yo hablo
    con
    las madres a las que veo más mal, les digo «Échale ganas, primeramente Dios... Mira, ya nos pasó esto, ¿ya qué
    vamos a
    hacer? Mas que salir adelante». Platicando nos apoyamos unas a otras.</p>
  <p>Pero las autoridades no buscan, no escarban bien, porque dicen que no hay maquinaria para escarbar. Al
    comisionado le
    dieron dinero, 14 millones de pesos, para las búsquedas. Se necesita herramienta y perros para que vayan y busquen
    ahí
    donde encontraron la credencial. Yo hasta estoy pensando que el día que vayan a buscar al predio del caso de mi
    Alfredo, porque en dos años no lo han hecho, hay que llevar maquinaria y perros, aunque los tenga que pagar yo de
    mi
    bolsa, porque el comisionado no creo que vaya a pagar ni a mover un dedo. Es que no hacen nada, no buscan.
    Nosotros
    hemos tenido que comprar hasta las varillas, para buscarlos en las fosas.</p>
  <p>Van varios familiares perdidos del colectivo que se han encontrado, pero ni siquiera enterrados: en al menos dos
    de
    los casos, los cuerpos estuvieron todo el tiempo en Semefo pero no los entregaban. Uno duró por lo menos un año
    ahí.
    Yo creo que es porque las autoridades no hacen la investigación, no nos enseñan bien los cuerpos, no hacen
    correctamente el cotejo ni el examen del ADN, en mi caso se tardaron meses para procesar mi prueba. Quizá es
    porque
    tienen mucho trabajo, les falta gente, no sé, no me lo explico. Cómo es que guardan en la morgue un cuerpo durante
    tanto tiempo y su familia sufriendo. Yo no quisiera pasar por eso.</p>
  <p>Lo de la búsqueda en la fosa de Uriangato fue solo un simulacro. Las autoridades ya habían ido antes de invitar a
    los
    colectivos; dijeron que encontraron huesos y más cosas, pero en realidad no hicieron nada, yo no vi que sacaran
    algo
    de las fosas. El operativo de búsqueda terminó pronto porque, dijeron, «ahí andan los malos». No entiendo porqué
    se
    pararon los trabajos, si se supone que había policías cuidando.</p>
  <p>Además del colectivo, estoy en un grupo de superación personal que también me ha ayudado a superar las pruebas de
    la
    vida. Cuando mis hijos estaban más chicos, tuve un problema con el alcohol, eso desembocó en más problemas con mi
    familia, uno de ellos siguió mi mal ejemplo.</p>
  <p>Pero yo no quería ver a mi hijo destruir su vida, pedí ayuda y pudimos ayudarlo. Ahí, en esos grupos de
    psicología
    las pláticas, las conferencias, me ayudaron a salir y brindarle a mi hijo el apoyo que necesitaba. A la fecha
    continúo
    yendo. Incluso mi hija ya se animó a dar conferencias, yo todavía no. Aún así, reflexiono mucho ahí, siento que
    eso me
    ha dado fortaleza para soportar tantos problemas. Pero eso no me quita de la cabeza que no tendría que haber
    pasado
    por esto, que mi hijo no tendría que haber desaparecido.</p>
  <img src="assets/elia/5.jpg" class="img-fluid my-5" alt="">
  <p>De ahora que Alfredo falta, me he enfermado. El mero día que mi hijo cumplió un año desaparecido (el 21 de
    octubre de
    2019) me puse muy mal, me tuvieron que operar de la vesícula, duré 15 días en el hospital.</p>
  <p>Fui a una marcha en Ciudad de México recién operada, pero fui.</p>
  <p>Me duele constantemente un brazo, del hombro, que no puedo ni dormir del dolor, y también me duele una rodilla.
    En la
    mañana amanecí con un derrame en el ojo y mucho dolor de cabeza. Como solo tengo Seguro Popular y para una
    consulta
    hay que salir desde las 6 de la mañana, y yo tengo que irme a trabajar, mejor me aguanto. Alfredo siempre estaba
    al
    pendiente de mí, cuando me enfermaba aquí estaba cuidándome o mandaba a su esposa a cuidarme.</p>
  <p>Ahora ya no tengo interés de nada... La casa no me interesa, ni mi vida, nada. Casi al grado de dejarme morir
    pero
    tengo que levantarme por mi otros hijos y mi hija. Ella apenas acaba de regresar a trabajar luego de dos años, por
    andar buscando a su hermano, aquí y allá, conmigo.</p>
  <p>Yo le creí a Diego, el gobernador, cuando se reunió con los colectivos, cuando sí estuvo Zamarripa, el fiscal.
    Ese
    que no había querido ir a otras reuniones con nosotros y que luego siguió faltando. Ese día, Diego nos escuchó,
    nos
    abrazó, hasta le di una carta donde le pedía ayuda para encontrar a mi hijo. Tiempo después, en la Fiscalía me
    enseñaron esa carta y otra que mi hija le entregó al Presidente López Obrador en su propia mano. Ahora todas esas
    cartas, porque fueron más de dos, están archivadas en el expediente, pero nunca tuvimos contestación y seguimos
    sin
    noticias de Alfredo.</p>
  <p>A pesar de todo, yo tengo fe en que el gobierno van a encontrar a mi hijo, que las autoridades lo van a buscar y
    lo
    van a encontrar. Me gustaría saber qué pasó, quién se lo llevó, por qué se lo llevaron. Qué les hizo él para que
    se lo
    llevaran. Solo así volveré a tener paz.</p>
  <p class="mt-5"><em>*Elia es el nombre ficticio que se dio a la buscadora por seguridad</em></p>


  <div class="intro-container black d-flex justify-content-center my-5">
    <div class="col-md-3 align-self-center">
      <img src="assets/elia/silueta.jpg" alt="" class="img-fluid">
    </div>
  </div>
</div>

