<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top black "  [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand logo" routerLink="/">
    <img src="assets/nav/poplab.jpg" class="img-fluid"  alt="">
  </a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link waves-light name" mdbWavesEffect routerLink="/bibiana">
          <img src="assets/nav/bibiana.png" alt="">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light name" mdbWavesEffect routerLink="/elia">
          <img src="assets/nav/elia.png" alt="">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-linkh waves-light name " mdbWavesEffect routerLink="/mariana">
          <img src="assets/nav/mariana.png" alt="">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light name" mdbWavesEffect routerLink="/matilde">
          <img src="assets/nav/matilde.png" alt="">
        </a>
      </li>
    </ul>
    <div class="name">
      <img src="assets/nav/cuandoEllasBuscan-1.jpg" class="title" alt="">
    </div>

  </links>
  <!-- Collapsible content -->

</mdb-navbar>
