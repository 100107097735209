import {Component, OnInit} from '@angular/core';
import {HeadersService} from '../../services/headers.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private header: HeadersService
  ) {
  }

  ngOnInit(): void {
    this.header.setHeader(
      'Estas mujeres se construyeron como las buscadoras que son, ' +
      'porque han logrado sobrevivir y han dejado atrás todo tipo de violencias y ' +
      'entornos agraviantes: embarazos adolescentes; violencia familiar de padres o esposos, ' +
      'adicciones, pobreza, discriminación. Es su propio rastro el que reconoceremos en estas historias.',
      '/assets/portadas/vero.jpg',
      'Cuando ellas buscan | POPLab',
      ''
    );
  }
}
