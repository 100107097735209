import { Component, OnInit } from '@angular/core';
import {HeadersService} from '../../services/headers.service';

@Component({
  selector: 'app-matilde',
  templateUrl: './matilde.component.html',
  styleUrls: ['./matilde.component.scss']
})
export class MatildeComponent implements OnInit {

  constructor(
    private header: HeadersService
  ) { }

  ngOnInit(): void {
    this.header.setHeader(
      'Lo encontré sin la ayuda de ninguna autoridad, solo el apoyo de mi familia y mis compañeras del ' +
      'colectivo al que me uní cuando me dí cuenta que la Fiscalía no iba a hacer nada.',
      '/assets/portadas/matilde-d.jpg',
      'A Matilde la Fiscalía la obligó a buscar a su esposo | POPLab',
      '/matilde'
    );
  }

}
