import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {BibianaComponent} from './pages/bibiana/bibiana.component';
import {EliaComponent} from './pages/elia/elia.component';
import {MarianaComponent} from './pages/mariana/mariana.component';
import {MatildeComponent} from './pages/matilde/matilde.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'bibiana',
    component: BibianaComponent
  },
  {
    path: 'elia',
    component: EliaComponent
  },
  {
    path: 'mariana',
    component: MarianaComponent
  },
  {
    path: 'matilde',
    component: MatildeComponent
  },
  {
    path: '**',
    component: HomeComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
